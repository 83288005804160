import { create } from "zustand";

const useStore = create((set) => ({
  appState: "login",
  setAppState: (value) => set(() => ({ appState: value })),
  globeState: "hidden",
  setGlobeState: (value) => set(() => ({ globeState: value })),
  networkState: "hidden",
  setNetworkState: (value) => set(() => ({ networkState: value })),
  audioState: "hidden",
  setAudioState: (value) => set(() => ({ audioState: value })),
  AIState: "hidden",
  setAIState: (value) => set(() => ({ AIState: value })),
  rotationPosition: 0,
  setRotationPosition: (value) => set(() => ({ rotationPosition: value })),

  overrideTargetRotation: null,
  setOverrideTargetRotation: (value) =>
    set(() => ({ overrideTargetRotation: value })),

  activeProject: null,
  setActiveProject: (value) => set(() => ({ activeProject: value })),

  activeMobileProject: null,
  setActiveMobileProject: (value) =>
    set(() => ({ activeMobileProject: value })),

  showArrows: true,
  setShowArrows: (value) => set(() => ({ showArrows: value })),

  // showAbout: true,
  // setShowAbout: (value) => set(() => ({ showAbout: value })),

  soundOn: localStorage.getItem("soundOn")
    ? localStorage.getItem("soundOn") === "true"
      ? true
      : false
    : true,
  setSoundOn: (bool) => set(() => ({ soundOn: bool })),

  playSound: null,
  setPlaySound: (string) => set(() => ({ playSound: string })),

  stopSound: null,
  setStopSound: (string) => set(() => ({ stopSound: string })),

  showAbout: false,
  setShowAbout: (string) => set(() => ({ showAbout: string })),

  progress: 0,
  setProgress: (number) => set(() => ({ progress: number })),
  activeSection: null,
  setActiveSection: (number) => set(() => ({ activeSection: number })),
  navTo: null,
  setNavTo: (number) => set(() => ({ navTo: number })),
  activeCaseStudy: null,
  setActiveCaseStudy: (number) => set(() => ({ activeCaseStudy: number })),
  mobileProgress: 0,
  setMobileProgress: (number) => set(() => ({ mobileProgress: number })),
  mobileContentProgress: 0,
  setMobileContentProgress: (number) =>
    set(() => ({ mobileContentProgress: number })),
  mobileNavActive: null,
  setMobileNavActive: (value) => set(() => ({ mobileNavActive: value })),
  loadProgress: 0,
  setLoadProgress: (number) => set(() => ({ loadProgress: number })),
  loaded: false,
  setLoaded: (bool) => set(() => ({ loaded: bool })),
  fontsLoaded: false,
  setFontsLoaded: (bool) => set(() => ({ fontsLoaded: bool })),
  contentLoaded: false,
  setContentLoaded: (bool) => set(() => ({ contentLoaded: bool })),
}));

export default useStore;
