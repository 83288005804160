/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 M_Shape_02.glb 
*/

import React, { useRef } from "react";
import {
  useGLTF,
  useAnimations,
  MeshDistortMaterial,
  MeshTransmissionMaterial,
  MeshWobbleMaterial,
} from "@react-three/drei";
import { DoubleSide, MeshPhysicalMaterial } from "three";
import { useFrame } from "@react-three/fiber";

export function Model(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(
    "./assets/models//M_Shape_02.glb"
  );
  const { actions } = useAnimations(animations, group);

  const p1 = useRef();
  const p2 = useRef();

  useFrame(() => {
    p1.current.rotation.y += 0.002;
    p1.current.rotation.z -= 0.004;
    p1.current.rotation.x += 0.003;
    p2.current.rotation.y -= 0.005;
    p2.current.rotation.z += 0.003;
    p2.current.rotation.x += 0.003;
  });

  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[0.05, 0.05, 0]}>
        <mesh
          name="M_Shape_02_Int"
          geometry={nodes.M_Shape_02_Int.geometry}
          // material={materials["01 - Default"]}
          scale={0.831 * 4.0}
          ref={p1}
        >
          <MeshDistortMaterial
            // samples={6}
            // resolution={512}
            // thickness={-1}
            // anisotropy={1.25}
            // opacity={0.9}
            // transmission={1.0}
            // transparent={true}
            radius={1.0}
            speed={0.7}
            distort={0.3}
            metalness={0.3}
            roughness={0.5}
            color={"#ddeeff"}
            transparent={true}
            opacity={0.05}
          />
        </mesh>
        <mesh
          name="M_Shape_02_Ext"
          geometry={nodes.M_Shape_02_Ext.geometry}
          // material={materials["01 - Default"]}
          ref={p2}
          scale={4}
        >
          <MeshDistortMaterial
            // samples={6}
            // resolution={512}
            // thickness={-1}
            // anisotropy={0.25}
            // opacity={0.9}
            transmission={1.0}
            transparent={true}
            opacity={0.05}
            speed={1.0}
            //radius={0.0}
            color={"#ddeeff"}
            radius={1.0}
            distort={0.3}
            metalness={0.3}
            roughness={0.5}
          />
        </mesh>
      </group>
    </group>
  );
}

useGLTF.preload("./assets/models//M_Shape_02.glb");
