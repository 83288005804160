import { useEffect } from "react";
import "./App.scss";
import About from "./components/About/About";
import Audio from "./components/Audio/Audio";
import Content from "./components/Content/Content";
import Header from "./components/Header/Header";
import { useData } from "./data/data";

function App() {
  const fetchData = useData((state) => state.fetch);
  fetchData();
  useEffect(() => {
    // fetch(
    //   "https://cdn.contentful.com/spaces/on8qued785p7/entries?access_token=cl6q2si_9ofguPKQlj-lWoAnta0VTjJKL8mKTYYKOGo"
    // )
    //   .then((res) => {
    //     return res.json();
    //   })
    //   .then((data) => {
    //     console.log(data);
    //   });
  }, []);

  return (
    <>
      <div className="overlay crt-flicker crt-colorsep crt-scanlines"></div>
      <div className={`App crt-flicker `}>
        <Audio />
        <Content />
        <Header />
        <About />
      </div>
    </>
  );
}

export default App;
