import "./AI.scss";
import gsap from "gsap";
import { useEffect, useRef } from "react";
import AIShell from "../AIShell/AIShell";

const AI = () => {
  const ref = useRef();

  return (
    <div ref={ref} className="ai">
      <AIShell />
    </div>
  );
};

export default AI;
