import "./AboutButton.scss";
import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import useStore from "../../store";

function AboutButton(props) {
  const tl = useRef();
  const ref = useRef();
  const AIState = useStore((state) => state.AIState);
  const setShowAbout = useStore((state) => state.setShowAbout);
  const showAbout = useStore((state) => state.showAbout);

  const aboutClicked = () => {
    // setShowAbout(!showAbout);
  };

  return <div onClick={aboutClicked} className="about-button"></div>;
}

export default AboutButton;
