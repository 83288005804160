import "./Terminal.scss";
import gsap from "gsap";
import { ScrambleTextPlugin } from "gsap/ScrambleTextPlugin";
import { TextPlugin } from "gsap/TextPlugin";
import { useEffect, useRef, useState } from "react";
import useStore from "../../store";
import Globe from "../Globe/Globe";
import Network from "../Network/Network";
import { useData } from "../../data/data";
import parse from "html-react-parser";

gsap.registerPlugin(ScrambleTextPlugin);
gsap.registerPlugin(TextPlugin);

const ProjectList = () => {
  const scrollingElement2 = useRef();
  const appState = useStore((state) => state.appState);
  const activeProject = useStore((state) => state.activeProject);
  const tl = useRef();
  const _p = useRef();
  const data = useData((state) => state.data);

  useEffect(() => {
    _p.current = "`>> Initializing Projects...<br /><br />";
    data.projects.slice(0, 4).forEach((p, i) => {
      _p.current += ">> Loading..." + p.title + "-" + p.subTitle + "<br />";
    });
  }, []);

  useEffect(() => {
    //   console.log(appState);
    if (activeProject === null || appState === "login") return;
    _p.current +=
      ">> Loading..." +
      data.projects[activeProject].title +
      "-" +
      data.projects[activeProject].subTitle +
      "<br />";
    if (tl.current) tl.current.pause();
    tl.current = gsap.timeline();
    // tl.current.call(() => {
    //   setAIState("ready");
    // });
    tl.current.to(".col-text7", {
      text: {
        value: _p.current,
        type: "diff",
        speed: 200,
      },
      duration: 0.25,
      onUpdate: () => {
        scrollingElement2.current.scrollTo(
          0,
          scrollingElement2.current.scrollHeight + 800
        );
      },
    });
  }, [activeProject]);

  return <div ref={scrollingElement2} className="col-text7"></div>;
};

function Terminal() {
  const appState = useStore((state) => state.appState);
  const setAppState = useStore((state) => state.setAppState);
  const setGlobeState = useStore((state) => state.setGlobeState);
  const setNetworkState = useStore((state) => state.setNetworkState);
  const setAIState = useStore((state) => state.setAIState);
  const setAudioState = useStore((state) => state.setAudioState);
  const showAbout = useStore((state) => state.showAbout);
  const [ip, setIP] = useState("");
  const [location, setLocation] = useState("");

  const [showingAbout, setShowingAbout] = useState(false);
  const data = useData((state) => state.data);

  const about = useRef();
  const aboutDetail = useRef();
  const aboutDetailEmail = useRef();

  const scrollingElement1 = useRef();
  const scrollingElement2 = useRef();
  const terminal = useRef();
  const tl = useRef();
  const _p = useRef();

  useEffect(() => {
    _p.current = "`>> Initializing Projects...<br /><br />";
    data.projects.slice(0, 4).forEach((p, i) => {
      _p.current += ">> Loading..." + p.title + "-" + p.subTitle + "<br />";
    });
    //tmp
    // set up all the data we need
    // fetch("https://api.ipify.org/?format=json")
    //   .then((res) => {
    //     return res.json();
    //   })
    //   .then((data) => {
    //     console.log(data);
    //     setIP(data.ip);

    //     fetch("https://ipinfo.io/" + data.ip + "?token=e8c0d76115bab7")
    //       .then((res) => {
    //         return res.json();
    //       })
    //       .then((data) => {
    //         console.log(data);
    //         setLocation(data);
    //       });
    //   });

    const updateButtons = () => {
      const aboutButton = document.querySelector(".about-button");
      const emailButton = document.querySelector(".email-button");
      const aboutButtonBase = document.querySelector(".col-about");
      const emailButtonBase = document.querySelector(".col-about-detail-email");

      const b1 = aboutButtonBase.getBoundingClientRect();
      const b2 = emailButtonBase.getBoundingClientRect();
      aboutButton.style.top = b1.top + "px";
      emailButton.style.top = b2.top + "px";
    };

    const timer = setInterval(updateButtons, 200);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    //
    if (appState !== "start") return;
    if (appState === "start") {
      if (tl.current) tl.current.pause();
      tl.current = gsap.timeline({ delay: 0.0 });

      tl.current.to(".col-text1", {
        text: {
          value: ` <br /><br />Initializing [namethemachine] Terminal...<br /
          >>> Establishing connection...<br /><br />
        `,
          speed: 20,
        },
        duration: 1,
      });

      tl.current.call(() => {
        setNetworkState("show");
      });

      tl.current.call(
        () => {
          setAIState("show");
        },
        null,
        1
      );
      tl.current.call(() => {
        setGlobeState("show");
      });
      tl.current.to(".col-text2", {
        text: {
          value: `testing [ network ] connection...<br />
       
          >> Initializing neural network...<br /><br />
          Neural network architecture: Deep Learning Model 3.2 <br />
          Activating neural pathways...<br />
          >> System online.<br /><br />
          >> Establishing user interface connection...<br /><br />
          `,
          type: "diff",
          speed: 10,
        },
        duration: 2,
        onUpdate: () => {
          scrollingElement1.current.scrollTo(0, terminal.current.scrollHeight);
        },
      });

      tl.current.to(about.current, {
        autoAlpha: 1,
        duration: 0.5,
        ease: "bounce.inOut",
      });

      tl.current.to(".col-about-detail", {
        text: {
          value: data.about.text,
          type: "diff",
          speed: 10,
        },
        duration: 1,
        onUpdate: () => {
          scrollingElement1.current.scrollTo(0, terminal.current.scrollHeight);
        },
      });
      tl.current.to(".col-about-detail-email", {
        text: {
          value: data.about.email,
          type: "diff",
          speed: 10,
        },
        duration: 1,
        onUpdate: () => {
          scrollingElement1.current.scrollTo(0, terminal.current.scrollHeight);
        },
      });
      tl.current.call(() => {
        setAIState("ready");
      });

      tl.current.to(".col-text7", {
        text: {
          value: _p.current,
          type: "diff",
          speed: 10,
        },
        duration: 3,
        onUpdate: () => {
          scrollingElement1.current.scrollTo(
            0,
            terminal.current.scrollHeight + 800
          );
        },
      });
    }
  }, [appState]);

  useEffect(() => {
    if (tl.current) tl.current.pause();
    tl.current = gsap.timeline({ delay: 0.0 });
    if (!showAbout) {
      // tl.current.set(".about-button", { top: "14vw" });
      // tl.current.set(".email-button", { top: "-20vw" });
      tl.current.to(".col-about-detail", {
        text: {
          value: "",
          type: "diff",
          speed: 100,
        },
        duration: 0.1,
        onUpdate: () => {
          scrollingElement1.current.scrollTo(0, terminal.current.scrollHeight);
        },
      });
      tl.current.to(".col-about-detail-email", {
        text: {
          value: "",
          type: "diff",
          speed: 100,
        },
        duration: 0.1,
        onUpdate: () => {
          scrollingElement1.current.scrollTo(0, terminal.current.scrollHeight);
        },
      });
    } else {
      // tl.current.set(".about-button", { top: "8vw" });
      // tl.current.set(".email-button", { top: "17vw" });
      tl.current.to(".col-about-detail", {
        text: {
          value: data.about.text,
          type: "diff",
          speed: 10,
        },
        duration: 1,
        onUpdate: () => {
          scrollingElement1.current.scrollTo(0, terminal.current.scrollHeight);
        },
      });
      tl.current.to(".col-about-detail-email", {
        text: {
          value: data.about.email,
          type: "diff",
          speed: 10,
        },
        duration: 1,
        onUpdate: () => {
          scrollingElement1.current.scrollTo(0, terminal.current.scrollHeight);
        },
      });
    }
  }, [showAbout]);

  const emailClicked = () => {
    window.open("mailto:" + data.about.email, "_self");
  };

  return (
    <div ref={terminal} className="terminal">
      <div ref={scrollingElement1} className="col-1 col">
        <div className="col-text1"></div>
        <Network location={location} />
        <div className="col-text2"></div>
        <button ref={about} className="col-about">
          {data.about.button}
        </button>
        <div ref={aboutDetail} className="col-about-detail"></div>
        <div
          onClick={emailClicked}
          ref={aboutDetailEmail}
          className="col-about-detail-email"
        ></div>
      </div>
      <Globe location={location} />
      <ProjectList />
    </div>
  );
}

export default Terminal;
