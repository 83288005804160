import "./ProjectDetail.scss";
import gsap from "gsap";
import { useEffect, useRef, useState } from "react";

import useStore from "../../store";

import { useData } from "../../data/data";
import ReactPlayer from "react-player";

const ProjectDetail = () => {
  const ref = useRef();
  const title = useRef();
  const subTitle = useRef();
  const tags = useRef();
  const contentBlocks = useRef();
  const tl = useRef();
  const boxContent = useRef();
  const [activeImage, setActiveImage] = useState(null);
  const [activeVideo, setActiveVideo] = useState(null);
  const data = useData((state) => state.data);

  const activeProject = useStore((state) => state.activeProject);
  const setActiveProject = useStore((state) => state.setActiveProject);
  const activeMobileProject = useStore((state) => state.activeMobileProject);
  const setActiveMobileProject = useStore(
    (state) => state.setActiveMobileProject
  );
  const AIState = useStore((state) => state.AIState);

  useEffect(() => {
    //console.log(AIState);
    if (activeProject === null || AIState !== "ready") return;
    // console.log("active project is now", activeProject);
    // hide project
    if (tl.current) tl.current.pause();
    tl.current = gsap.timeline();
    tl.current.to(ref.current, {
      transformOrigin: "0% 50%",
      scaleY: 0.0,
      ease: "bounce.inOut",
      duration: 0.1,
    });
    // tl.current.to(ref.current, {
    //   scaleX: 0,
    //   duration: 0.1,
    //   ease: "bounce.in",
    // });

    tl.current.call(() => {
      title.current.innerHTML = data.projects[activeProject].title;
      subTitle.current.innerHTML = data.projects[activeProject].subTitle;
      tags.current.innerHTML = data.projects[activeProject].tags;
      setActiveImage(data.projects[activeProject].featureImage || null);
      setActiveVideo(data.projects[activeProject].featureVideo || null);
      // let _c = "";
      // data.projects[activeProject].content?.forEach((c, i) => {
      //   if (c.type === "text-block") {
      //     _c += c.text;
      //   }
      //   if (c.type === "image") {
      //     _c += "<img class='image' src='" + c.src + "' />";
      //   }
      // });
      contentBlocks.current.innerHTML =
        data.projects[activeProject].content || "";
      boxContent.current.scrollTop = 0;
    });
    tl.current.set(ref.current, { right: "-1%" });
    // tl.current.to(ref.current, {
    //   scaleX: 1,
    //   ease: "circ.out",
    //   duration: 0.5,
    //   transformOrigin: "0% 50%",
    //   delay: 0.2,
    // });
    tl.current.to(ref.current, {
      scaleY: data.projects[activeProject].isLabel !== true ? 1 : 0,
      ease: "elastic.inOut",
      duration: 0.2,
      delay: 0.2,
    });
  }, [activeProject, AIState]);

  useEffect(() => {
    if (activeMobileProject === null) {
      tl.current = gsap.timeline();
      tl.current.to(ref.current, {
        transformOrigin: "0% 50%",
        scaleY: 0.0,
        ease: "bounce.inOut",
        duration: 0.1,
      });
      return;
    }

    if (tl.current) tl.current.pause();
    tl.current = gsap.timeline();
    tl.current.to(ref.current, {
      transformOrigin: "0% 50%",
      scaleY: 0.0,
      ease: "bounce.inOut",
      duration: 0.1,
    });

    tl.current.call(() => {
      title.current.innerHTML = data.projects[activeMobileProject].title;
      subTitle.current.innerHTML = data.projects[activeMobileProject].subTitle;
      tags.current.innerHTML = data.projects[activeMobileProject].tags;
      setActiveImage(data.projects[activeMobileProject].featureImage || null);
      setActiveVideo(data.projects[activeMobileProject].featureVideo || null);
      // let _c = "";
      // data.projects[activeMobileProject].content?.forEach((c, i) => {
      //   if (c.type === "text-block") {
      //     _c += c.text;
      //   }
      //   if (c.type === "image") {
      //     _c += "<img class='image' src='" + c.src + "' />";
      //   }
      // });
      contentBlocks.current.innerHTML =
        data.projects[activeMobileProject].content || "";
      boxContent.current.scrollTop = 0;
    });
    tl.current.set(ref.current, { right: "-1%" });
    // tl.current.to(ref.current, {
    //   scaleX: 1,
    //   ease: "circ.out",
    //   duration: 0.5,
    //   transformOrigin: "0% 50%",
    //   delay: 0.2,
    // });
    tl.current.to(ref.current, {
      scaleY: data.projects[activeMobileProject].isLabel !== true ? 1 : 0,
      ease: "elastic.inOut",
      duration: 0.2,
      delay: 0.2,
    });
  }, [activeMobileProject]);

  const closeDetail = () => {
    // setActiveProject(null);
    setActiveMobileProject(null);
  };

  return (
    <div ref={ref} className="project-detail">
      <button className="close-button" onClick={closeDetail}>
        X
      </button>
      <img className="box" src="./assets/images/detail-box-1.png" />
      <div className="box-content" ref={boxContent}>
        <h2 className="sub-title" ref={subTitle}></h2>
        <h1 className="title" ref={title}></h1>
        <h3 className="tags" ref={tags}></h3>
        {activeImage && <img className="feature-image" src={activeImage} />}
        {activeVideo && (
          <div className="player-wrapper">
            <ReactPlayer
              className="feature-video"
              url={activeVideo}
              width="100%"
              height="100%"
              controls
              playsinline
              //playing
            />
          </div>
        )}
        <div ref={contentBlocks} className="content-blocks"></div>
      </div>
    </div>
  );
};

export default ProjectDetail;
