import "./Header.scss";
import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import useStore from "../../store";
import { useData } from "../../data/data";

function Header(props) {
  const tl = useRef();
  const ref = useRef();
  const soundOnRef = useRef();
  const soundOffRef = useRef();
  const setShowAbout = useStore((state) => state.setShowAbout);
  const AIState = useStore((state) => state.AIState);

  const setSoundOn = useStore((state) => state.setSoundOn);
  const soundOn = useStore((state) => state.soundOn);
  const data = useData((state) => state.data);

  useEffect(() => {
    if (AIState !== "show") return;
    tl.current = gsap.timeline({});
    tl.current.set(ref.current, { autoAlpha: 1, y: -100 });
    tl.current.to(ref.current, { duration: 0.5, y: 0 });
  }, [AIState]);

  const aboutClicked = () => {
    setShowAbout(true);
  };

  const toggleSound = () => {
    setSoundOn(!soundOn);
  };

  return (
    <div ref={ref} className={`header`}>
      <div className="left">
        <div className="header-title">[namethemachine]</div>
        <div onClick={aboutClicked} className="header-about">
          {data.about.button}
        </div>
      </div>
      <button className="sound-button" onClick={toggleSound}>
        [sound&nbsp;
        <span
          className={`sound-on ${soundOn ? "active" : ""}`}
          ref={soundOnRef}
        >
          on
        </span>
        /
        <span
          className={`sound-off ${!soundOn ? "active" : ""}`}
          ref={soundOffRef}
        >
          off
        </span>
        ]
      </button>
    </div>
  );
}

export default Header;
