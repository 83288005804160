import { Canvas, useFrame, useThree } from "@react-three/fiber";
import "./AIShell.scss";
import gsap from "gsap";
import { Suspense, useEffect, useRef } from "react";
import {
  Environment,
  Lightformer,
  MeshDistortMaterial,
  OrbitControls,
  Plane,
  Ring,
  Sphere,
  Torus,
  useGLTF,
  useTexture,
} from "@react-three/drei";
import {
  Bloom,
  DepthOfField,
  EffectComposer,
  Noise,
  Vignette,
} from "@react-three/postprocessing";
import {
  DoubleSide,
  Color,
  MeshPhongMaterial,
  NoToneMapping,
  AdditiveBlending,
  SRGBColorSpace,
} from "three";
import useStore from "../../store";
import { useDrag, useGesture } from "@use-gesture/react";
import { Model } from "./M_Shape_02";
import { sRGBEncoding } from "@react-three/drei/helpers/deprecated";
import ProjectWindows from "../ProjectWindows/ProjectWindows";

import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { useData } from "../../data/data";

gsap.registerPlugin(MotionPathPlugin);

const AIInteractiveShell = () => {
  const ref = useRef();
  const rotRef = useRef();
  const AIState = useStore((state) => state.AIState);
  const setRotationPosition = useStore((state) => state.setRotationPosition);
  const setShowArrows = useStore((state) => state.setShowArrows);
  const overrideTargetRotation = useStore(
    (state) => state.overrideTargetRotation
  );
  const startYPos = useRef(0);
  const targetYPos = useRef(0);
  const startRotation = useRef(0);
  const targetRotation = useRef(0);
  const dragging = useRef(false);
  const data = useData((state) => state.data);

  const tl = useRef();

  const handleScroll = (e) => {
    //console.log(e);
    //targetYPos.current = e.deltaY;
    startRotation.current = rotRef.current.rotation.z;
    targetRotation.current = startRotation.current - e.deltaY * -0.03;

    // startYPos.current = state.xy[1];
    // startRotation.current = rotRef.current.rotation.z;
    // dragging.current = true;
    setShowArrows(false);
  };

  useEffect(() => {
    if (overrideTargetRotation !== null) {
      targetRotation.current = overrideTargetRotation;
    }
  }, [overrideTargetRotation]);

  useEffect(() => {
    targetRotation.current = (data.projects.length / 3) * -1.25;
    rotRef.current.rotation.z = (data.projects.length / 3) * -1.25;
    startRotation.current = rotRef.current.rotation.z;

    document.querySelector(".ai").addEventListener("wheel", handleScroll);

    return () => {
      document.querySelector(".ai").removeEventListener("wheel", handleScroll);
    };
  }, []);

  useEffect(() => {
    // aiGroup.current.position.x = -6.5;
    if (AIState === "show") {
      if (tl.current) tl.current.pause();
      tl.current = gsap.timeline({ delay: 0 });
      tl.current.call(() => {
        targetRotation.current = (data.projects.length / 3) * -1.25;
        rotRef.current.rotation.z = (data.projects.length / 3) * -1.25;
      });
    }
  }, [AIState]);

  const drag = useGesture({
    onDrag: (state) => {
      state.event.stopPropagation();
      //console.log(state);
      targetYPos.current = state.xy[1];
      targetRotation.current =
        startRotation.current -
        (startYPos.current - targetYPos.current) * -0.01;
    },
    onDragStart: (state) => {
      //console.log("drag started");
      // set startYPos state.xy
      startYPos.current = state.xy[1];
      startRotation.current = rotRef.current.rotation.z;
      dragging.current = true;

      setShowArrows(false);
    },
    onDragEnd: (state) => {
      //console.log("drag ended");
      dragging.current = false;
    },
  });

  useFrame(() => {
    //console.log("rotation", Math.round(rotRef.current.rotation.z * 100) / 100);
    // each card is 1.25 units

    // we want to always be in the 2nd set.
    // so in a range of 1.25 * lenght of set to 1.25 * length of set *2
    //

    const l = data.projects.length / 3;
    const limitOnBottom = (l + 5) * -1.25;
    const limitOnTop = (2 * l + 5) * -1.25;

    if (rotRef.current.rotation.z > limitOnBottom) {
      console.log("exceeded bottom limit");
      rotRef.current.rotation.z = rotRef.current.rotation.z - l * 1.25;
      targetRotation.current = targetRotation.current - l * 1.25;
    }

    if (rotRef.current.rotation.z < limitOnTop) {
      console.log("exceeded top limit");
      rotRef.current.rotation.z = rotRef.current.rotation.z + l * 1.25;
      targetRotation.current = targetRotation.current + l * 1.25;
    }

    rotRef.current.rotation.z +=
      (targetRotation.current - rotRef.current.rotation.z) * 0.1;
    setRotationPosition(rotRef.current.rotation.z);

    //console.log("rotation", rotRef.current.rotation.z);
  });

  return (
    <group ref={ref}>
      <group
        {...drag()}
        ref={rotRef}
        onClick={() => {
          // console.log("asdas");
        }}
      >
        <Sphere
          // visible={false}
          // onClick={() => {
          //   console.log("click");
          // }}
          args={[3, 8]}
        >
          <meshBasicMaterial transparent={true} opacity={0.0} />
        </Sphere>
        <OutterRing1 />
      </group>
    </group>
  );
};

const GeoCore = ({ ...props }) => {
  const ref = useRef();
  const ref1 = useRef();
  const ref2 = useRef();
  const { invalidate, camera, gl } = useThree();

  //const { scene, animations } = useGLTF("./assets/models/M_Shape_02.glb");
  // const { actions, mixer } = useAnimations(animations, scene);

  // useEffect(() => {
  //   scene.traverse((mesh, i) => {
  //     if (mesh.material) {
  //       mesh.material.metalness = 1.0;
  //       mesh.material.roughness = 0.0;
  //       //mesh.material = new MeshDistortMaterial();
  //       //mesh.material.color = new Color(0xccddff);
  //     }

  //     if (mesh.name === "M_Shape_02_Ext") {
  //       ref1.current = mesh;

  //       //ref1.current.material.envMapIntensity = 5.6;
  //       // mesh.material.color = new Color(props.color);
  //       // mesh.material.envMapIntensity = 0.6;
  //       // mesh.material.roughness = 0.2;
  //       // mesh.material.metalness = 0.1;
  //       // mesh.material = new MeshPhongMaterial({
  //       //   color: new Color(props.color),
  //       // });
  //     }

  //     if (mesh.name === "M_Shape_02_Int") {
  //       ref2.current = mesh;
  //       //mesh.visible = false;
  //     }

  //     // mesh.frustumCulled = false;
  //   });
  //   // actions["animation_0"].timeScale = 0.5;
  //   // actions["animation_0"].play();
  // }, []);

  // useFrame((state, delta) => {
  //   // console.log("render");
  //   ref1.current.rotation.x += 0.006;
  //   ref1.current.rotation.y -= 0.004;
  //   ref1.current.rotation.z -= 0.005;
  //   ref2.current.rotation.x -= 0.004;
  //   ref2.current.rotation.y += 0.006;
  //   ref2.current.rotation.z -= 0.007;
  //   //if (!props.isPaused) invalidate();
  // });

  return <Model />;
};

const Ring1 = () => {
  const flare = useRef();
  const flareMat = useRef();

  const tex = useTexture("../assets/models/ring-1.png");
  //const tex2 = useTexture("../assets/models/flare-2.png");
  // tex.colorSpace = SRGBColorSpace;

  return (
    <Plane
      args={[1920 / 160, 1080 / 160]}
      position={[0.35, -0.05, 0]}
      ref={flare}
    >
      <meshBasicMaterial
        map={tex}
        transparent={true}
        ref={flareMat}
        depthTest={false}
        opacity={0.7}
      />
    </Plane>
  );
};

const Ring2 = () => {
  const flare = useRef();
  const flareMat = useRef();

  //const tex = useTexture("../assets/models/ring-1.png");
  const tex2 = useTexture("../assets/models/flare-11.png");
  // tex2.colorSpace = SRGBColorSpace;

  // improve with random after adn make cooler
  useEffect(() => {
    // gsap.to(flare.current.position, {
    //   yoyo: true,
    //   repeat: -1,
    //   x: -0.03,
    //   y: 0.01,
    //   z: -0.01,
    //   duration: 3.0,
    // });
  }, []);

  return (
    <Plane args={[6, 6]} position={[0.0, 0.0, -0.2]} ref={flare}>
      <meshBasicMaterial
        map={tex2}
        transparent={true}
        ref={flareMat}
        depthWrite={true}
        depthTest={true}
        opacity={0.8}
        //color={"#aabbff"}
        //blending={AdditiveBlending}
      />
    </Plane>
  );
};

const OutterRing1 = () => {
  const p1 = useRef();
  const p2 = useRef();
  const p3 = useRef();
  const p4 = useRef();
  const a1 = useRef();
  const flareMat = useRef();
  const arrowMat = useRef();
  const AIState = useStore((state) => state.AIState);
  const tl = useRef();

  //const tex = useTexture("../assets/models/ring-1.png");
  const tex2 = useTexture("../assets/models/outter-ring-1-b.png");
  //const arrows = useTexture("../assets/images/drag-arrows.png");
  // tex2.colorSpace = SRGBColorSpace;

  useEffect(() => {
    if (AIState === "show") {
      if (tl.current) tl.current.pause();
      tl.current = gsap.timeline({ delay: 4 });
      tl.current.to([p1.current.material], {
        opacity: 0.05,
        ease: "bounce.inOut",
        duration: 1,
      });
      tl.current.to([p2.current.material], {
        opacity: 0.04,
        ease: "bounce.inOut",
        duration: 1,
      });
      tl.current.to([p3.current.material], {
        opacity: 0.04,
        ease: "bounce.inOut",
        duration: 1,
      });
      tl.current.to([p4.current.material], {
        opacity: 0.03,
        ease: "bounce.inOut",
        duration: 1,
      });
      tl.current.to(
        p1.current.rotation,
        {
          z: 2 * Math.PI,
          repeat: -1,
          ease: "none",
          duration: 200,
        },
        0
      );
      tl.current.to(
        p2.current.rotation,
        {
          z: -2 * Math.PI,
          repeat: -1,
          ease: "none",
          duration: 220,
        },
        0
      );
      tl.current.to(
        p3.current.rotation,
        {
          z: -2 * Math.PI,
          repeat: -1,
          ease: "none",
          duration: 260,
        },
        0
      );
      tl.current.to(
        p4.current.rotation,
        {
          z: 2 * Math.PI,
          repeat: -1,
          ease: "none",
          duration: 300,
        },
        0
      );
      // tl.current.to([a1.current.material], {
      //   opacity: 0.3,
      //   ease: "bounce.inOut",
      //   duration: 0.5,
      // });
    }
  }, [AIState]);

  return (
    <>
      <Plane args={[7, 7]} position={[0.0, 0.0, 0]} ref={p1}>
        <meshBasicMaterial
          map={tex2}
          transparent={true}
          ref={flareMat}
          depthWrite={true}
          depthTest={false}
          // opacity={0.3}
          opacity={0.0}
          color={"#5DE7FF"}
          blending={AdditiveBlending}
        />
      </Plane>
      <Plane args={[14, 14]} position={[0, 0, 0]} ref={p2}>
        <meshBasicMaterial
          map={tex2}
          transparent={true}
          ref={flareMat}
          depthWrite={true}
          depthTest={false}
          // opacity={0.007}
          opacity={0.0}
          color={"#ccddff"}
          // blending={AdditiveBlending}
        />
      </Plane>
      <Plane args={[21, 21]} position={[0, 0, 0]} ref={p3}>
        <meshBasicMaterial
          map={tex2}
          transparent={true}
          ref={flareMat}
          depthWrite={true}
          depthTest={false}
          //opacity={0.008}
          opacity={0.0}
          color={"#ccddff"}
          // blending={AdditiveBlending}
        />
      </Plane>
      <Plane args={[35, 35]} position={[0, 0, 0]} ref={p4}>
        <meshBasicMaterial
          map={tex2}
          transparent={true}
          ref={flareMat}
          depthWrite={true}
          depthTest={false}
          //opacity={0.008}
          opacity={0.0}
          color={"#ccddff"}
          // blending={AdditiveBlending}
        />
      </Plane>
      {/* <Plane args={[1.9 * 0.3, 10.5 * 0.3]} position={[0.0, 0.0, 0]} ref={a1}>
        <meshBasicMaterial
          map={arrows}
          transparent={true}
          ref={arrowMat}
          depthWrite={true}
          depthTest={false}
          // opacity={0.3}
          opacity={0.0}
          color={"#5DE7FF"}
          blending={AdditiveBlending}
        />
      </Plane> */}
    </>
  );
};

const Ring3 = () => {
  const flare = useRef();
  const flareMat = useRef();
  const ringa = useRef();

  //const tex = useTexture("../assets/models/ring-1.png");
  const tex2 = useTexture("../assets/models/flare-9.png");
  // tex2.encoding = sRGBEncoding;
  // tex2.colorSpace = SRGBColorSpace;

  useFrame(() => {});

  return (
    <>
      <Plane
        ref={ringa}
        args={[12, 3]}
        position={[0.05, 0.05, -10.5]}
        // ref={flare}
      >
        <meshBasicMaterial
          map={tex2}
          transparent={true}
          ref={flareMat}
          depthWrite={true}
          depthTest={true}
          opacity={0.2}
          color={"#66aaff"}
          // blending={AdditiveBlending}
        />
      </Plane>
      {/* <Plane args={[1, 30]} position={[0.05, 0.05, -0.5]} ref={flare}>
        <meshBasicMaterial
          map={tex2}
          transparent={true}
          ref={flareMat}
          depthWrite={true}
          depthTest={true}
          opacity={0.1}
          color={"#aaccff"}
          // blending={AdditiveBlending}
        />
      </Plane> */}
      {/* <Plane
        args={[0.5, 15]}
        position={[0.05, 0.05, -0.5]}
        rotation={[0, 0, -0.5]}
        ref={flare}
      >
        <meshBasicMaterial
          map={tex2}
          transparent={true}
          ref={flareMat}
          depthWrite={true}
          depthTest={true}
          opacity={0.1}
          color={"#aaccff"}
          // blending={AdditiveBlending}
        />
      </Plane> */}
    </>
  );
};

/* <mesh {...props} ref={ref} scale={[3.9, 3.9, 3.9]}>
 <primitive object={scene} />
  */

const Core = () => {
  const coreRef = useRef();
  const coreMat = useRef(0.0);
  const AIState = useStore((state) => state.AIState);

  const tl = useRef();

  const _AIState = useRef();

  useEffect(() => {
    _AIState.current = AIState;
  }, [AIState]);

  const handleResize = () => {
    // console.log(AIState);
    //if (AIState !== "ready") return;
    console.log(_AIState.current, window.innerWidth / window.innerHeight);
    const ratio = window.innerWidth / window.innerHeight;
    if (ratio < 1.4) {
      if (tl.current) tl.current.pause();
      tl.current = gsap.timeline({ delay: 0 });
      tl.current.to(coreRef.current.position, {
        x: 5 * (1 - ratio / 1.77),
        duration: 0.25,
      });
    } else {
      if (tl.current) tl.current.pause();
      tl.current = gsap.timeline({ delay: 0 });
      tl.current.to(coreRef.current.position, {
        x: 0,
        duration: 0.25,
      });
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (AIState === "show") {
      if (tl.current) tl.current.pause();
      tl.current = gsap.timeline();

      let o = { val: 0 };
      tl.current.to(o, {
        val: 0.9,
        ease: "back.inOut",
        duration: 3,
        delay: 2,
        onUpdate: () => {
          coreMat.current.radius = o.val;
          coreMat.current.opacity = o.val * 1.2;
        },
      });

      // anaimte in the other elements of the AI, spheres, shape circles etc and
      // start them anaimting , could be simple fade in of them

      // then get the system to anaimte to the left as the projects anamte on. thoses should probably be in place and open from scaleY 0 to 1...
    }
  }, [AIState]);

  useFrame(() => {
    coreRef.current.rotation.y -= 0.005;
    coreRef.current.rotation.z += 0.003;
    coreRef.current.rotation.x -= 0.005;
  });

  return (
    <>
      <Sphere ref={coreRef} args={[0.99, 128]}>
        <MeshDistortMaterial
          ref={coreMat}
          // ref={set}
          // envMap={envMap}
          // bumpMap={bumpMap}
          transmission={1.0}
          transparent={true}
          side={DoubleSide}
          color={"#99D7FF"}
          opacity={0.0}
          roughness={0.0}
          metalness={0.5}
          wireframe={true}
          // bumpScale={1.05}
          // clearcoat={1}
          // clearcoatRoughness={0.1}
          radius={0.0}
          distort={0.2}
          speed={0.02}
        />
      </Sphere>
      {/* <Sphere ref={coreRef} args={[1.05, 6]}>
        <MeshDistortMaterial
          ref={coreMat}
          // ref={set}
          // envMap={envMap}
          // bumpMap={bumpMap}
          transmission={1.0}
          transparent={true}
          side={DoubleSide}
          color={"#99D7FF"}
          opacity={1.0}
          roughness={0.0}
          metalness={1.0}
          wireframe={true}
          // bumpScale={1.05}
          // clearcoat={1}
          // clearcoatRoughness={0.1}
          radius={0.9}
          distort={0.1}
          speed={0.02}
        />
      </Sphere> */}
    </>
  );
};

const AIElements = () => {
  const aiGroup = useRef();
  const tl = useRef();
  const AIState = useStore((state) => state.AIState);
  const showArrows = useStore((state) => state.showArrows);

  //const { viewport } = useThree();

  // anaimte this after
  useEffect(() => {
    // aiGroup.current.position.x = -6.5;

    if (AIState === "show") {
      const scales = [
        { x: 0, z: 0 },
        { x: -2, z: -25 },
        { x: -6.5, z: 0 },
      ];
      if (tl.current) tl.current.pause();
      tl.current = gsap.timeline({ delay: 5 });
      tl.current.to(aiGroup.current.position, {
        // x: -6.0,
        motionPath: {
          path: scales,
          curviness: 2,
        },
        duration: 1.5,
        ease: "circ.inOut",
      });
    }
  }, [AIState]);

  const Arrows = () => {
    const ref = useRef();
    const refMat = useRef();
    const tex = useTexture("../assets/images/drag-arrows.png");
    const tl = useRef();

    useEffect(() => {
      if (AIState === "ready") {
        if (tl.current) tl.current.pause();
        tl.current = gsap.timeline({
          repeat: -1,
          yoyo: true,
          repeatDelay: 0.7,
        });
        tl.current.to(refMat.current, {
          opacity: 0.08,
          duration: 0.7,
          ease: "circ.inOut",
        });
      }
    }, [AIState]);

    useEffect(() => {
      if (showArrows === false) {
        if (tl.current) tl.current.pause();
        tl.current = gsap.timeline({});
        tl.current.to(refMat.current, {
          opacity: 0.0,
          duration: 0.7,
          ease: "circ.inOut",
        });
      }
    }, [showArrows]);

    return (
      <Plane
        ref={ref}
        args={[41 * 0.005, 404 * 0.005]}
        position={[3.55, 0, 0]}
        // ref={flare}
      >
        <meshBasicMaterial
          map={tex}
          transparent={true}
          ref={refMat}
          depthWrite={false}
          depthTest={false}
          opacity={0}
          color={"#aaddff"}
          // blending={AdditiveBlending}
        />
      </Plane>
    );
  };

  return (
    <group ref={aiGroup}>
      <AIInteractiveShell />
      <Core />
      {/* <GeoCore /> */}
      {/* 
      <Ring1 />
      <Ring2 />
      <Ring3 /> */}
      {/* <Ring1 /> */}
      {/* <OutterRing1 /> */}
      {/* 
      
      <Ring2 />
      <Ring3 /> */}
      <ProjectWindows />
      <Arrows />
    </group>
  );
};

const AIShell = () => {
  const canvasRef = useRef();

  return (
    <Suspense fallback={null}>
      <Canvas
        ref={canvasRef}
        className="ai-shell"
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          pointerEvents: "none",
        }}
        camera={{ fov: 10, position: [0, 0, 50] }}
        // gl={{ toneMapping: NoToneMapping }}
      >
        {/* <pointLight position={[0, 0, -3]} intensity={0.0} color={"#42D1E9"} /> */}
        {/* <Ring3 />
      // 
      <pointLight position={[0, 0, 0]} intensity={100.0} color={"#42D1E9"} />
      */}
        <AIElements />

        <Environment preset={"studio"} />
        {/* <Lightformer
          form="rect" // circle | ring | rect (optional, default = rect)
          intensity={1} // power level (optional = 1)
          color="white" // (optional = white)
          scale={[10, 5]} // Scale it any way you prefer (optional = [1, 1])
          target={[0, 0, 0]} // Target position (optional = undefined)
        /> */}
        {/* </Environment> */}
        {/* <OrbitControls /> */}
        {/* <EffectComposer multisampling={0} disableNormalPass={true}>
          <Bloom
            luminanceThreshold={0.0}
            intensity={0.1}
            luminanceSmoothing={1.0}
            height={400}
            width={400}
            // opacity={0.1}
          />
        </EffectComposer> */}
        <EffectComposer>
          <Bloom
            luminanceThreshold={2.0}
            mipmapBlur
            width={100}
            height={100}
            intensity={0.6}
            opacity={0.9}
          />
        </EffectComposer>
      </Canvas>
    </Suspense>
  );
};

export default AIShell;
