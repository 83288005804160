import "./Content.scss";
import Login from "../Login/Login";
import Terminal from "../Terminal/Terminal";
import ProjectDetail from "../ProjectDetail/ProjectDetail";
import { useEffect, useRef } from "react";
import gsap from "gsap";
import { axisTop } from "d3";
import useStore from "../../store";
import AI from "../AI/AI";
import AboutButton from "../AboutButton/AboutButton";
import EmailButton from "../EmailButton/EmailButton";
import MobileProjectWindows from "../MobileProjectWindows/MobileProjectWindows";

function Content() {
  const glitch = useRef();
  const glitch2 = useRef();
  const about = useRef();
  const tl = useRef();

  useEffect(() => {
    // if (tl.current) tl.current.pause();
    // tl.current = gsap.timeline({ repeatRefresh: true, yoyo: true, repeat: -1 });
    // tl.current.to(
    //   glitch.current,
    //   {
    //     opacity: "random(.07,.08)",
    //     x: "random(-15,5)",
    //     y: "random(-10,10)",
    //     scaleX: "random(1,1.01)",
    //     duration: 0.3,
    //   },
    //   0
    // );
    // tl.current.to(
    //   glitch2.current,
    //   {
    //     opacity: "random(.05,.07)",
    //     x: "random(-15,5)",
    //     y: "random(-10,10)",
    //     scaleX: "random(1,1.01)",
    //     duration: 2,
    //   },
    //   0
    // );
  }, []);

  return (
    <div className="content">
      <Login />
      <Terminal />
      <AI />
      <MobileProjectWindows />
      <ProjectDetail />
      <AboutButton />
      <EmailButton />
      <img
        ref={glitch}
        className="background-glitch"
        src="./assets/models/gltich.jpg"
      />
      <img
        ref={glitch2}
        className="background-glitch2"
        src="./assets/models/gltich.jpg"
      />
    </div>
  );
}

export default Content;
