import { Canvas, useFrame, useThree } from "@react-three/fiber";
import "./ProjectWindows.scss";
import gsap from "gsap";
import { useEffect, useRef } from "react";
import {
  Dodecahedron,
  Environment,
  Html,
  Line,
  MeshDistortMaterial,
  OrbitControls,
  Plane,
  Ring,
  Sphere,
  Torus,
  useGLTF,
  useTexture,
} from "@react-three/drei";
import {
  Bloom,
  DepthOfField,
  EffectComposer,
  Noise,
  Vignette,
} from "@react-three/postprocessing";
import { DoubleSide, Color, MeshPhongMaterial, BufferAttribute } from "three";
import useStore from "../../store";

import { useData } from "../../data/data";

const ProjectWindow = ({ project, index }) => {
  const ref = useRef();
  const lineRef = useRef();
  const lineRef2 = useRef();
  const rotationPosition = useStore((state) => state.rotationPosition);
  const setActiveProject = useStore((state) => state.setActiveProject);
  const activeProject = useStore((state) => state.activeProject);
  const AIState = useStore((state) => state.AIState);
  const setPlaySound = useStore((state) => state.setPlaySound);
  const data = useData((state) => state.data);

  const setOverrideTargetRotation = useStore(
    (state) => state.setOverrideTargetRotation
  );

  const appState = useStore((state) => state.appState);

  const outerGroup = useRef();
  const htmlRef = useRef();
  const boxImage = useRef();
  const boxInner = useRef();

  const tl = useRef();

  const boxTex = useTexture("../assets/images/box-1.png");

  useEffect(() => {
    console.log(index);
  }, []);

  useFrame(() => {
    ref.current.position.y = rotationPosition * 2.0 + index * 2.5;

    ref.current.position.x =
      6.2 - Math.abs(ref.current.position.y * ref.current.position.y * 0.05);
    //ref.current.position.z = 0;

    // determine active project
    if (Math.abs(ref.current.position.y + 0.3) < 1.4 && AIState === "ready") {
      // console.log("got this one as active:", index, ref.current.position.y);
      setActiveProject(index);

      if (boxImage.current) {
        boxImage.current.classList.add("active");
        boxInner.current.classList.add("active");
      }
    } else {
      if (boxImage.current) {
        boxImage.current.classList.remove("active");
        boxInner.current.classList.remove("active");
      }
    }
  });

  useEffect(() => {
    if (tl.current) tl.current.pause();
    tl.current = gsap.timeline({ delay: 0.0 });
    tl.current.set(lineRef2.current.scale, {
      x: 0,
      y: 0,
      z: 0,
    });
    tl.current.to(lineRef2.current.scale, {
      x: 1,
      y: 1,
      z: 1,
      duration: 0.8,
      ease: "circ.out",
    });
    setPlaySound("click");
  }, [activeProject]);

  useEffect(() => {
    if (AIState === "ready") {
      gsap.to(outerGroup.current.position, {
        x: 0,
        duration: 0.3,
        ease: "back.inOut",
      });
    }

    // fade in or scale up html
  }, [AIState]);

  const projectWindowClicked = (e) => {
    console.log("project clicked", index);
    console.log(ref.current.position.y);
    // calc the rotaiton we need to adjsut the position to be 0
    //ref.current.position.y = rotationPosition * 2.0 + index * 3.0;
    setOverrideTargetRotation(-index * 1.25);
  };

  return (
    <group ref={outerGroup} position={[-100, 0, 0]}>
      <group ref={ref}>
        <Plane args={[3.6, 2.2]}>
          <meshBasicMaterial
            transparent={true}
            opacity={0.0}
            //opacity={0.4}
            //map={boxTex}
            scale={index % 2 ? [1, -1, 1] : [1, 1, 1]}
          />
        </Plane>
        <Html
          wrapperClass="project-window"
          center
          className={`project-window-inner ${data.projects[index].class || ""}`}
          ref={boxInner}
        >
          <img
            ref={boxImage}
            className="box"
            src="./assets/images/box-1.png"
            onClick={projectWindowClicked}
          />
          <img className="image" src={data.projects[index].thumb} />

          <div className="titles-wrapper">
            <h2 className="sub-title">{data.projects[index].subTitle}</h2>
            <h1 className="title">{data.projects[index].title}</h1>
            <h3 className="tags">{data.projects[index].tags}</h3>
          </div>
          {/*
           */}
        </Html>
      </group>
      <Line
        ref={lineRef}
        visible={AIState === "ready" && data.projects[index].isLabel !== true}
        points={[
          [0, 0, 0],
          [
            ref.current ? ref.current.position.x - 1.5 : 0,
            ref.current ? ref.current.position.y : 0,
            ref.current ? ref.current.position.z : 0,
          ],
        ]} // Array of points, Array<Vector3 | Vector2 | [number, number, number] | [number, number] | number>
        color={activeProject === index ? "white" : "#42D1E9"} // Default
        lineWidth={activeProject === index ? 1.5 : 1} // In pixels (default)
        depthWrite={true}
        depthTest={false}
        transparent={true}
        opacity={
          activeProject === index ? 0.5 : activeProject === null ? 0 : 0.05
        }
      />
      <Line
        ref={lineRef2}
        visible={
          AIState === "ready" &&
          activeProject === index &&
          data.projects[index].isLabel !== true
        }
        points={[
          [0, 0, 0],
          [5, ref.current ? -ref.current.position.y : 0, 0],
          // [3.5, ref.current ? -ref.current.position.y : 0, 0],
        ]}
        position={[
          ref.current ? ref.current.position.x : 0,
          ref.current ? ref.current.position.y : 0,
          ref.current ? ref.current.position.z : 0,
        ]}
        color={"white"}
        lineWidth={1.5}
        depthWrite={true}
        depthTest={false}
        transparent={true}
        opacity={0.15}
      />
    </group>
  );
};

const ProjectWindows = () => {
  const canvasRef = useRef();
  const data = useData((state) => state.data);
  // //console.log("render proejct windwos");
  // const activeProject = useStore((state) => state.activeProject);

  // useEffect(() => {
  //   console.log("active project is now", activeProject);
  // }, [activeProject]);

  useEffect(() => {
    // augment array of projects to allow wrapping
    // const last5 = data.projects.slice(-5);
    // const first5 = data.projects.slice(0, 5);
    data.projects = data.projects.concat(data.projects.concat(data.projects));
  }, []);

  return (
    <>
      {data.projects.map((p, i) => {
        return <ProjectWindow key={"p_" + i} project={p} index={i} />;
      })}
    </>
  );
};

export default ProjectWindows;
