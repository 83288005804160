import { create } from "zustand";

const data = {
  meta: {
    title: "[namethemachine]",
  },
  about: {
    button: "[about]",
    text: ">>>_objective : build a studio focused on delivering innovative work inside of an ever-changing, evolving, and convergent creative media landscape...",
    email: "contact@namethemachine.com",
    emailText: "contact@namethemachine.com",
  },
  projects: [
    {
      id: 0,
      isLabel: true,
      class: "intro",
      subTitle: "",
      title: "[projects]",
      tags: "",
      thumb: "",
    },
    {
      id: 1,
      subTitle: "RADIOHEAD",
      title: "KID A MNESIA EXHIBITION",
      tags: "Unreal Engine, Wwise",
      thumb: "./assets/images/projects/radiohead-kid-a/radiohead-kid-a.jpg",
      featureImage:
        "./assets/images/projects/radiohead-kid-a/radiohead-kid-a.jpg",
      featureVideo: "",
      content: `
        <p>An upside-down digital/analogue universe created from original artwork and recordings to commemorate 21 years of Radiohead’s Kid A and Amnesiac.</p>
        
        <p><a href='https://en.wikipedia.org/wiki/Kid_A_Mnesia_Exhibition' target='_blank'>Wikipedia</a></p>
        <img class='image' src= './assets/images/projects/radiohead-kid-a/radiohead-kid-a-image-2.jpg' />
        <p>Developed in collaboration with:</p>

        <p>> <a href='https://radiohead.com/' target='_blank'>Radiohead, Stanley Donwood, & Nigel Godrich</a></p>
        
        <p>> <a href='https://www.instagram.com/deadskinboy/?hl=en' target='_blank'>Sean Evans aka Deadskinboy</a></p>
        
        <p>> <a href='https://agp.digital/project/kid-a-mnesia-exhibition/' target='_blank'>Arbitrarily Good Productions</a></p>
        
        <p>> <a href='https://www.epicgames.com/store/en-US/p/kid-a-mnesia-exhibition' target='_blank'>Epic Games Publishing</a></p>
        
        <p>Released as a free virtual experience on <a href='https://www.epicgames.com/store/en-US/p/kid-a-mnesia-exhibition' target='_blank'>Epic Games Store</a> and <a href='https://store.playstation.com/en-us/concept/10003085/' target='_blank' >Playstation 5</a></p>
        
        `,
    },
    {
      id: 2,
      subTitle: "FAITHLESS",
      title: "Synthesizer",
      tags: "Cinema4D, Unreal Engine",
      thumb:
        "./assets/images/projects/faithless-synthesizer/faithless-synthesizer.png",
      featureImage: "",
      featureVideo: "https://www.youtube.com/watch?v=KPcSPDdRUis",
      content: `<p>> video created and shot in unreal engine + cinema4D</p>

        <p>> directed by Strangeloop, produced by [namethemachine]</p>
        
        <p>> vfx, graphics programming, environment art by <a href='https://agp.digital/project/synthesizer-faithless' target='_blank'>Arbitrarily Good Productions</a></p>
        
        <p> > vfx, environment art, character art by <a href='https://strangeloop-studios.com/' target='_blank' >Strangeloop Studios</a></p>`,
    },
    {
      id: 3,
      subTitle: "NIKE",
      title: "Airphoria Volume 2",
      tags: "Unreal Engine",
      thumb: "./assets/images/projects/nike-airphoria/nike-airphoria.png",
      featureImage:
        "./assets/images/projects/nike-airphoria/nike-airphoria.png",
      featureVideo: "",
      content: `<p><a href='https://www.fortnite.com/@nike_inc/2887-3398-8922' target='_blank' >Nike Airphoria Volume 2</a>  – Enter Nike’s DN DIMENSION, where a mysterious new element, Dynamic Air, has disrupted the world of Airphoria. Save our hero, Max, by traversing surreal portals to collect four Air Chambers that will activate the newest sneaker grail. Gather Nike collectibles and race the clock on your journey to Max’s Lab.</p>

       <p><a href='https://soundcloud.com/dotswoosh' target='_blank' Soundtrack Produced by [namethemachine]</a></p>
        
        <p>Game Audio Sound Design, Music, and Implementation by [namethemachine]</p>
        
        <p><a href='https://www.instagram.com/reel/C4_vm8gvfIi/?utm_source=ig_embed&utm_campaign=loading' target='_blank'>
        View this post on Instagram
        </a>`,
    },
    {
      id: 4,
      subTitle: "INTEL",
      title: "CES 2018",
      tags: "3DS Max, Ableton Live, Max/MSP, Maya, TouchDesigner, Unity",
      thumb: "./assets/images/projects/intel-ces-2018/intel-ces-2018.jpg",
      featureImage:
        "./assets/images/projects/intel-ces-2018/intel-ces-2018.jpg",
      content: `<p>
        [namethemachine] created a wide array of real-time interactive visual compositions for the performance portion of Intel’s CES 2018 keynote address in Las Vegas – Simple numeric data displays, dynamic fluid simulations, and animated interactive characters in real time. These visual scenes were informed by data generated from performers on stage, positional trackers on drones, and AI ‘musicians’.</p>
        <img class='image' src= "./assets/images/projects/intel-ces-2018/intel-ces-1.jpg" />
         <p>Musicians played sensor based instruments & [ntm]’s real-time graphics reacted to the nuances of each musician’s playing.</p>
         <img class='image' src= "./assets/images/projects/intel-ces-2018/intel-ces-2.jpg" />
        <p>[ntm] created a pair of highly detailed and expressive characters in Unity to portray the visual representation of Intel’s AI musicians who improvised on stage with Kevin Doucette, the musical director and lead musician of the performance.</p>
        <img class='image' src= "./assets/images/projects/intel-ces-2018/intel-ces-3.jpg" />
        <img class='image' src= "./assets/images/projects/intel-ces-2018/intel-ces-4.jpg" />
        <p>An interactive fluid simulation and other data display graphics surrounded a dancer and were driven by the positional sensors she wore on her wrists and ankles.</p>
        <img class='image' src= "./assets/images/projects/intel-ces-2018/intel-ces-2018.jpg" />
        <p><b>Credits</b></p>
        <p><b>Visuals Team</b><br />
        Peter Sistrom – Interactive Visuals Designer / Director<br />
        Vincent Houze – Fluid/flex solver system and renderer<br />
        Mary Franck – Procedural tools designer<br />
        Lucas Morgan – ArtNet LED Orb control in dance portion :: ImagineX<br />
        Eric Mintzer – ArtNet LED Orb control in dance portion :: ImagineX<br />
        Dom Davis – Data Graphics and Interactive Assets<br />
        Evan Pierre – Data graphics, assets, and general tech<br />
        Max Chang – Data graphics, assets, and lead hardware tech<br />
        </p>

        <p><b>AI Avatar Team</b><br />
        Chelsea Hash – Lead Technical Artist<br />
        Devon Chapman – Avatar Artist<br />
        Alejandro Crawford<br />
        </p>
        
        <p><b>Music Team</b><br />
        Kevin Doucette – Musical Director<br />
        Playback Engineer – Fred Carlton :: Nerdmatics<br />
        Interactive Music Engineer – Chris Legaspi :: Nerdmatics<br />
        </p>

        <p>
        Sensors and Data | Intel<br />
        Matt Pinner :: TouchTech.io<br />
        Adelle Lin :: TouchTech.io<br />
        Team at Intel<br />
        </p>
        
        <p>
        <b>[namethemachine]</b><br />
        Matthew Davis – producer<br />
        Evan Hembacher – producer<br />
        </p>

       <p> Show produced and directed by Clarity Creative</p>

        
        `,
    },
    {
      id: 5,
      subTitle: "DAVID GILMOUR",
      title: "Hollywood Bowl",
      tags: "TouchDesigner",
      thumb:
        "./assets/images/projects/david-gilmour-hollywood-bowl/david-gilmour-hollywood-bowl.jpg",
      featureImage:
        "./assets/images/projects/david-gilmour-hollywood-bowl/david-gilmour-hollywood-bowl.jpg",
      content: `<p>David Gilmour played LA’s Hollywood Bowl in 2016 for the first time since Pink Floyd performed there in 1972. For the occasion, Marc Brickman, long time lightning designer for Pink Floyd, decided to projection map the bowl with nearly 30 high powered projectors. [namethemachine] was part of the creative and technical team that engineered the mapping and created custom architecturally mapped content for the show.</p>
          <p>Working with Strangeloop Studios and VER, [ntm] created a technical mapping template to guide the workflow of the various animators and editors working on the media.  We also created a preview system so each piece of media was accompanied by an easy to view preview of the resulting map result in 3D.  Peter Sistrom led the technical content workflow, coordinating between the production team and the animation team.</p>
           <img class='image' src= "./assets/images/projects/david-gilmour-hollywood-bowl/david-gilmour-hollywood-bowl-1.jpg"" />
          <p> In addition to technical workflow guidance, [ntm] also provided a large set of mapped animation to the media catalog which were showcased during songs such as “Sorrow” and “Comfortably Numb” as well as a sneaky,  illusionary and psychedelic piece for the setbreak of the show.</p>
          <img class='image' src= "./assets/images/projects/david-gilmour-hollywood-bowl/david-gilmour-hollywood-bowl-2.jpg"" />`,
    },
    {
      id: 6,
      subTitle: "DRAKE",
      title: "OVO Sound",
      tags: "Ableton Live, Max/MSP",
      thumb: "./assets/images/projects/ovo-sound/ovo-sound.jpg",
      featureImage: "./assets/images/projects/ovo-sound/ovo-sound.jpg",
      content: `
        
<p>> Assassination Vacation EUROPE ’19 – Ableton Live playback & Max/MSP Programming</p>

<p>> Aubrey & The Three Migos ’18 – Ableton Live playback & Max/MSP Programming</p>

<p>> Drake + Future SUMMER ’16 World Tour – Ableton Live playback & Max/MSP Programming></p>

<p>> <a href='http://en.wikipedia.org/wiki/Would_You_like_a_Tour%3F' target='_blank'>Drake 2013-2014</a> WOULD YOU LIKE A TOUR? – Ableton Live playback & Max/MSP Programming</p>

<p>> Drake 2012 CLUB PARADISE Tour – Ableton Live playback & Max/MSP Programming</p>

<p>> <a href='http://en.wikipedia.org/wiki/Would_You_like_a_Tour%3F' target='_blank'>Drake’s Annual OVO Fest</a> – Ableton Live playback & Max/MSP Programming</p>

<p>> Various Award Shows, Television Appearances, Special Events, and Personal Appearances 2012 – 2016</p>`,
    },
    {
      id: 7,
      subTitle: "CHROME HEARTS",
      title: "www.",
      tags: "Cinema4D, Maya, Unreal Engine",
      thumb: "./assets/images/projects/chrome-hearts-www/chrome-hearts-www.png",
      featureImage:
        "./assets/images/projects/chrome-hearts-www/chrome-hearts-www.png",
      content: [
        {
          type: "text-block",
          text: `<p>>_ video created and shot in unreal engine for the launch of chrome hearts’ website (creative dir: laurie lynn stark, director: ben wolin, music: jesse jo stark & thomas hunter, animation: arbitrarily good productions )</p>

          <p><a href='https://www.instagram.com/p/CCM2EVLHFFd/?igshid=17xd6vwjn7jju' target='_blank'>https://www.instagram.com/p/CCM2EVLHFFd/?igshid=17xd6vwjn7jju</a></p>

          <p><a href='https://www.instagram.com/p/CCW_jXTH_z-/?igshid=1j76bcw0em2ss' target='_blank'>https://www.instagram.com/p/CCW_jXTH_z-/?igshid=1j76bcw0em2ss</a></p>
          <p><a href='https://www.instagram.com/p/CCXRNsCnJSG/?igshid=1do2y75nq88mg' target='_blank'>https://www.instagram.com/p/CCXRNsCnJSG/?igshid=1do2y75nq88mg</a></p>
          <p><a href='https://www.instagram.com/p/CCXlJ18nbZ8/?igshid=komycw0ebfg6' target='_blank'>https://www.instagram.com/p/CCXlJ18nbZ8/?igshid=komycw0ebfg6</a></p>
       `,
        },
      ],
    },
    {
      id: 8,
      subTitle: "THE GLITCH MOB",
      title: "The Blade",
      tags: "Ableton Live, Lemur, Max/MSP",
      thumb: "./assets/images/projects/the-blade/the-blade.jpg",
    },
    {
      id: 9,
      subTitle: "INTEL",
      title: "Gaga x Intel",
      tags: "Ableton Live, Cinema4D, Octane,TouchDesigner",
      thumb:
        "./assets/images/projects/intel-gaga-x-intel/intel-gaga-x-intel.jpg",
      featureImage:
        "./assets/images/projects/intel-gaga-x-intel/intel-gaga-x-intel.jpg",
      content: `<p>Lady Gaga was supported by Intel Curie technology during her 2016 Grammy’s performance. She wore a ring equipped with a device that captured data based on her movement throughout the show. [namethemachine] and Strangeloop Studios transformed this information into a unique audio/visual expression.</p>
      <img class='image' src= "./assets/images/projects/intel-gaga-x-intel/intel-gaga-x-intel-1.jpg" />
       <p>Peter Sistrom analyzed the data provided by Intel to be used for the animation in the creation of the piece.</p>
      <p>The piece was co-directed by Peter Sistrom of [namethemachine] and David Wexler of Strangeloop Studios. The two of them contributed heavily to the animation work along with a team of two other animators. Matthew Davis of [namethemachine] scored the piece.</p>
      <img class='image' src= "./assets/images/projects/intel-gaga-x-intel/intel-gaga-x-intel-2.jpg" />
      `,
    },
    {
      id: 10,
      subTitle: "JORDAN WOLFSON",
      title: "Female Figure",
      tags: "Ableton Live, Max/MSP",
      thumb: "./assets/images/projects/female-figure/female-figure.jpg",
      featureVideo: "https://www.youtube.com/watch?v=auix-QLJwSo",
      content: `<p><i>Female Figure</i> combines film, installation, and performance in the form of a curvaceous, scantily clad woman covered in dirt marks and wearing a witch mask. Unlike the artist’s two-dimensional subjects, this life-size character was developed in close collaboration with a special effects studio in California used by major Hollywood productions. She dances in front of a mirror, speaks in Wolfson’s recorded voice and uses facial recognition technology to make eye contact with viewers.</p>`,
    },
    {
      id: 11,
      subTitle: "VITA MOTUS",
      title: "Riyadh SEASONS",
      tags: "Cinema4D, Maya, Unreal Engine",
      thumb:
        "./assets/images/projects/vita-motus-riyadh-seasons/vita-motus-riyadh-seasons.png",
      featureImage:
        "./assets/images/projects/vita-motus-riyadh-seasons/vita-motus-riyadh-seasons.png",

      content: [
        {
          type: "text-block",
          text: `<p>In 2019, Riyadh transformed the way people in Saudi Arabia experience live music.</p>

          <p>For the city’s SEASONS Festival, [ntm] created a video show by building a series of Unreal-engine based worlds.</p>
          
          <p>These scenes were optimized for realtime, and 8k footage was shot in-engine using custom cinematic capture rigs.</p>
          
          <p>Created in collaboration with :</p>
          
          <p>> <a href='https://vitamotus.com/' target='_blank'>Vita Motus</a></p>
          
          <p>> <a href='https://agp.digital/project/riyadh-seasons' target='_blank'>Arbitrarily Good Productions</a></p>`,
        },
        {
          type: "image",
          src: "./assets/images/projects/vita-motus-riyadh-seasons/vita-motus-riyadh-seasons-1.png",
        },
        {
          type: "image",
          src: "./assets/images/projects/vita-motus-riyadh-seasons/vita-motus-riyadh-seasons-2.png",
        },
        {
          type: "image",
          src: "./assets/images/projects/vita-motus-riyadh-seasons/vita-motus-riyadh-seasons-3.png",
        },
        {
          type: "image",
          src: "./assets/images/projects/vita-motus-riyadh-seasons/vita-motus-riyadh-seasons-4.png",
        },
        {
          type: "image",
          src: "./assets/images/projects/vita-motus-riyadh-seasons/vita-motus-riyadh-seasons-5.png",
        },
        {
          type: "image",
          src: "./assets/images/projects/vita-motus-riyadh-seasons/vita-motus-riyadh-seasons-6.png",
        },
        {
          type: "image",
          src: "./assets/images/projects/vita-motus-riyadh-seasons/vita-motus-riyadh-seasons-7.png",
        },
        {
          type: "image",
          src: "./assets/images/projects/vita-motus-riyadh-seasons/vita-motus-riyadh-seasons-8.png",
        },
        {
          type: "image",
          src: "./assets/images/projects/vita-motus-riyadh-seasons/vita-motus-riyadh-seasons-9.png",
        },
        {
          type: "image",
          src: "./assets/images/projects/vita-motus-riyadh-seasons/vita-motus-riyadh-seasons-10.png",
        },
        {
          type: "image",
          src: "./assets/images/projects/vita-motus-riyadh-seasons/vita-motus-riyadh-seasons-11.png",
        },
        {
          type: "image",
          src: "./assets/images/projects/vita-motus-riyadh-seasons/vita-motus-riyadh-seasons-12.png",
        },
        {
          type: "image",
          src: "./assets/images/projects/vita-motus-riyadh-seasons/vita-motus-riyadh-seasons-13.jpg",
        },
      ],
    },
    {
      id: 12,
      subTitle: "INTEL",
      title: "A.I.D.J.",
      tags: "Max/MSP, Maya, Touch Designer, Unity, Zbrush",
      thumb: "./assets/images/projects/intel-aidj/intel-aidj.png",
      featureVideo: "https://vimeo.com/328571529",
      content: [
        {
          type: "text-block",
          text: `<p>The “A.I.D.J.” is the third AI, neural-net driven, engine-hosted, procedural virtual character [ntm] has developed for Intel.</p>

        <p>The full set of AI Characters – a guitarist, bassist, and DJ – are the centerpiece of Intel’s promotional shows to advertise their latest AI processing chip, the Movidius Compute Stick.</p>
        
        <p>In 2018, our Avatars were on stage at Intel’s CES Keynote, TEDx, AI DevCon San Francisco, Computex Taiwan, and AI DevCon India.</p>
        
        <p>The AI system operates in a “call and response” format. First, a human player lays down a sequence of notes on a keyboard (the “call”). That sequence is processed by a musical neural net trained by Intel’s AI team to ingest and output music of the intended style (i.e. jazz, rock, latin). Then, the neural net generates and plays a new, unique sequence of notes, that takes cues from the rhythm and range of the call sequence (the “response”). The human responds, turning this feedback loop into an improvisational jam session between a human and AI musician.</p>`,
        },
        {
          type: "image",
          src: "./assets/images/projects/intel-aidj/intel-aidj-1.png",
        },
        {
          type: "image",
          src: "./assets/images/projects/intel-aidj/intel-aidj-1-b.png",
        },
        {
          type: "text-block",
          text: `<p>The DJ character is driven in Unity by real-time state and data streams coming from Intel’s Music AI Engine.</p>

        <p>He can dance, turn knobs and buttons, spin turntables, pump up the crowd, listen to his headphones, and look at his on-stage human counterpart, all at custom levels of intensity, without the system ever breaking or creating an unrealistic pose.</p>
        
        <p>xsAs the beat intensifies, so does the avatar’s posture. When the audio style shifts, so does the dancing style. When there’s a particularly exciting riff, the DJ pumps up the crowd.</p>`,
        },
        {
          type: "image",
          src: "./assets/images/projects/intel-aidj/intel-aidj-2.png",
        },
        {
          type: "text-block",
          text: `<p>Reactive VFX and shaders pulse to the flow of data processing and the musical jam session. For example, when the character is listening to musical input from a human counterpart, strands of neural pathways distort and glow as the AI processor “learns” then comes up with subsequent generative musical phrases.</p>

        <p> As the character plays what the Movidius processor has just generated, custom shaders in the arms then distort and glow with increasing intensity to the beat.</p>
        
        <p>The DJ podium also reacts to incoming MIDI note sequences, lighting up emissive shaders where each light corresponding to a note or range of notes.</p>`,
        },
        {
          type: "image",
          src: "./assets/images/projects/intel-aidj/intel-aidj-3.png",
        },
        {
          type: "image",
          src: "./assets/images/projects/intel-aidj/intel-aidj-4.png",
        },
        {
          type: "image",
          src: "./assets/images/projects/intel-aidj/intel-aidj-5.jpg",
        },
        {
          type: "image",
          src: "./assets/images/projects/intel-aidj/intel-aidj-6.png",
        },
        {
          type: "text-block",
          text: `<p>Powering these reactive particles, shaders, and animation system is a sophisticated OSC data pipeline.</p>

        <p>The incoming AI music track, which is generated by Intel’s musical neural net AI, is sent to our system via a Max/MSP patch and ingested into a local TouchDesigner app. The Touch App uses audio data to generate realistic gestures and animation cues to feed into the Unity application, creating the performative visual flair that is seen on stage.</p>`,
        },
      ],
    },
    {
      id: 13,
      subTitle: "INTEL",
      title: "Curie Shanghai",
      tags: "Ableton Live, Max/MSP, TouchDesigner",
      thumb:
        "./assets/images/projects/intel-curie-shanghai/intel-curie-shanghai.jpg",
      featureImage:
        "./assets/images/projects/intel-curie-shanghai/intel-curie-shanghai.jpg",

      content: [
        {
          type: "text-block",
          text: `<p>[namethemachine] helped create the musical spectacle before Intel’s Keynote speech at CES Asia in Shanghai. Kevin Doucette and DJ Ravidrums put on an intense performance using wireless Intel Curie devices, & [ntm] provided a new set of interactive graphics to represent and amplify the actions of the performers onstage. Enshrouded in whirling interactive particles Kevin and Ravi banged out a medley of hit songs on mostly invisible instruments.</p>`,
        },
        {
          type: "image",
          src: "./assets/images/projects/intel-curie-shanghai/intel-curie-shanghai-1.jpg",
        },
        {
          type: "image",
          src: "./assets/images/projects/intel-curie-shanghai/intel-curie-shanghai-2.jpg",
        },
        {
          type: "text-block",
          text: `<p><b>Credits :</b></p>

          <p>Visual Design and Direction : Peter Sistrom</p>
          
          <p>Lead Programmer : Peter Sistrom</p>
          
          <p>Assistant Programmers, Show Operators : Max Chang</p>
          
          <p>Audio Hardware and Playback : Nerdmatics</p>
          
          <p>Production Company : Clarity Creative</p>
          
          <p>Systems Integration and General Awesomness : Matt Pinner</p>`,
        },
      ],
    },
    {
      id: 14,
      subTitle: "THE DO LAB",
      title: "Lightning Stage",
      tags: "TouchDesigner, VDMX",
      thumb:
        "./assets/images/projects/the-do-lab-lightning-stage/the-do-lab-lightning-stage.jpg",
      featureImage:
        "./assets/images/projects/the-do-lab-lightning-stage/the-do-lab-lightning-stage.jpg",
      content: "[content coming soon]",
    },

    {
      id: 15,
      subTitle: "INTEL",
      title: "Wushu x Curie",
      tags: "TouchDesigner",
      thumb:
        "./assets/images/projects/intel-wushu-x-curie/intel-wushu-x-curie.jpg",
      featureImage:
        "./assets/images/projects/intel-wushu-x-curie/intel-wushu-x-curie.jpg",
      content: "[content coming soon]",
    },

    {
      id: 16,
      subTitle: "INTEL",
      title: "Parkour NYC",
      tags: "TouchDesigner",
      thumb: "./assets/images/projects/intel-parkour-nyc/intel-parkour-nyc.jpg",
      featureImage:
        "./assets/images/projects/intel-parkour-nyc/intel-parkour-nyc.jpg",
      content: "[content coming soon]",
    },

    {
      id: 17,
      subTitle: "INTEL",
      title: "Yago DeQuay",
      tags: "Ableton Live, Max/MSP, TouchDesigner",
      thumb: "./assets/images/projects/intel-yago-dequay/intel-yago-dequay.jpg",
      featureImage:
        "./assets/images/projects/intel-yago-dequay/intel-yago-dequay.jpg",
      content: "[content coming soon]",
    },

    {
      id: 18,
      subTitle: "LOS ANGELES PHILHARMONIC",
      title: "LA Philharmonic",
      tags: "Ableton Live, Max/MSP",
      thumb: "./assets/images/projects/la-philharmonic/la-philharmonic.jpg",
      featureImage:
        "./assets/images/projects/la-philharmonic/la-philharmonic.jpg",
      content: "[content coming soon]",
    },

    {
      id: 19,
      subTitle: "QUAREPUSHER",
      title: "Damogen Furies",
      tags: "TouchDesigner, VDMX",
      thumb: "./assets/images/projects/damogen-furies/damogen-furies.jpg",
      featureVideo: "https://vimeo.com/184819207",
      content: "[content coming soon]",
    },

    {
      id: 20,
      subTitle: "VITA MOTUS",
      title: "The Circuitry of Life",
      tags: "TouchDesigner",
      thumb:
        "./assets/images/projects/the-circuitry-of-life/the-circuitry-of-life.jpg",
      featureImage:
        "./assets/images/projects/the-circuitry-of-life/the-circuitry-of-life.jpg",
      content: "[content coming soon]",
    },

    {
      id: 21,
      subTitle: "",
      title: "AV Sessions I",
      tags: "Ableton Live, Max/MSP, TouchDesigner, VDMX",
      thumb: "./assets/images/projects/av-sessions-i/av-sessions-i.jpg",
      featureImage: "./assets/images/projects/av-sessions-i/av-sessions-i.jpg",
      content: "[content coming soon]",
    },

    {
      id: 22,
      subTitle: "BANKS",
      title: "Goddess",
      tags: "Ableton Live, DMXIS, Max/MSP",
      thumb: "./assets/images/projects/banks-goddess/banks-goddess.jpg",
      content: "[content coming soon]",
    },

    {
      id: 23,
      subTitle: "SUPRMASV",
      title: "Signal Snowboard",
      tags: "Arduino, Max/MSP",
      thumb: "./assets/images/projects/signal-snowboard/signal-snowboard.jpg",
      content: "[content coming soon]",
    },

    {
      id: 24,
      subTitle: "BASSNECTAR",
      title: "U.N.S.",
      tags: "Max/MSP, TouchDesigner",
      thumb: "./assets/images/projects/bass-nectar-uns/bass-nectar-uns.jpg",
      content: "[content coming soon]",
    },

    {
      id: 25,
      subTitle: "DESTROID",
      title: "Invasion",
      tags: "Ableton Live, Custom MIDI Guitars, Max/MSP",
      thumb: "./assets/images/projects/destroid-invasion/destroid-invasion.png",
      content: "[content coming soon]",
    },

    {
      id: 26,
      subTitle: "IOBEATS",
      title: "Heart Beats",
      tags: "Max/MSPP",
      thumb: "./assets/images/projects/heart-beats/heart-beats.jpg",
      content: "[content coming soon]",
    },

    {
      id: 27,
      subTitle: "30 SECONDS TO MARS",
      title: "Ohm Control",
      tags: "Max/MSP",
      thumb: "./assets/images/projects/ohm-control/ohm-control.jpg",
      content: "[content coming soon]",
    },

    {
      id: 28,
      subTitle: "STS9",
      title: "CNTRL:R",
      tags: "Ableton Live, Max/MSP, MIDI",
      thumb: "./assets/images/projects/sts9-cntrl-r/sts9-cntrl-r.jpg",
      content: "[content coming soon]",
    },

    {
      id: 29,
      subTitle: "GOOGLE",
      title: "Glass",
      tags: "Ableton Live, Max/MSP, TouchDesigner",
      thumb: "./assets/images/projects/google-glass/google-glass.jpg",
      content: "[content coming soon]",
    },

    {
      id: 30,
      subTitle: "SYYN LABS",
      title: "Glow",
      tags: "Ableton Live, Arduino, Max/MSP",
      thumb: "./assets/images/projects/syyn-labs-glow/syyn-labs-glow.jpg",
      content: "[content coming soon]",
    },

    {
      id: 31,
      subTitle: "TEDXUSC",
      title: "TEDx",
      tags: "Ableton Live, Max/MSP, TouchDesigner",
      thumb: "./assets/images/projects/tedx-usc-tedx/tedx-usc-tedx.jpg",
      content: "[content coming soon]",
    },

    {
      id: 32,
      subTitle: "",
      title: "Kinect",
      tags: "Max/MSP, OpenNI",
      thumb: "./assets/images/projects/kinect/kinect.jpg",
      content: "[content coming soon]",
    },

    {
      id: 33,
      subTitle: "MIMOSA",
      title: "Lightning in a Bottle",
      tags: "TouchDesigner",
      thumb:
        "./assets/images/projects/lightning-in-a-bottle/lightning-in-a-bottle.jpg",
      content: "[content coming soon]",
    },

    {
      id: 34,
      subTitle: "PORTER ROBINSON",
      title: "Spitfire",
      tags: "Max/MSP, TouchDesigner",
      thumb: "./assets/images/projects/spitfire/spitfire.jpg",
      content: "[content coming soon]",
    },

    {
      id: 35,
      subTitle: "STS9",
      title: "The Great Cycle Spectacles",
      tags: "TouchDesigner",
      thumb:
        "./assets/images/projects/the-great-cycle-spectacless/the-great-cycle-spectacless.jpg",
      content: "[content coming soon]",
    },

    {
      id: 36,
      subTitle: "ELECTRONIC CREATIVES",
      title: "Playback",
      tags: "Ableton Live, Max/MSP",
      thumb:
        "./assets/images/projects/electronic-creatives-playback/electronic-creatives-playback.jpg",
      content: "[content coming soon]",
    },

    {
      id: 37,
      subTitle: "VSQUARED LABS",
      title: "ISAM",
      tags: "TouchDesigner",
      thumb:
        "./assets/images/projects/vsquared-labs-isam/vsquared-labs-isam.jpg",
      content: "[content coming soon]",
    },
  ],
};

const useData = create((set) => ({
  data,
  fetch: async () => {
    console.log("Fetch data from API");
    let dataFetch = {};
    await fetch(
      "https://cdn.contentful.com/spaces/on8qued785p7/entries/?access_token=cl6q2si_9ofguPKQlj-lWoAnta0VTjJKL8mKTYYKOGo&include=2"
    )
      .then((res) => {
        return res.json();
      })
      .then((d) => {
        console.log(d);
        dataFetch = d;
      });

    // default data
    const newData = {};
    newData.about = data.about;
    newData.projects = data.projects;
    const newProjects = [];

    // iterate the results and update the default data
    if (dataFetch && dataFetch.items) {
      dataFetch.items.map((p, i) => {
        const type = p.sys.contentType.sys.id;
        if (type === "about") {
          newData.about = p.fields;
        }

        if (type === "project") {
          newProjects[parseInt(p.fields.id)] = p.fields;
          //console.log("processing", p.fields.title);
          // update the thumb
          if (p.fields.thumb && p.fields.thumb.sys !== undefined) {
            if (dataFetch.includes && dataFetch.includes.Asset) {
              dataFetch.includes.Asset.map((a, j) => {
                //console.log(a.sys, p.fields.thumb.sys);
                if (p.fields.thumb.sys && a.sys.id === p.fields.thumb.sys.id) {
                  newProjects[parseInt(p.fields.id)].thumb =
                    "https:" + a.fields.file.url;
                }
              });
            }
          }

          // update the featureImage
          if (
            p.fields.featureImage &&
            p.fields.featureImage.sys !== undefined
          ) {
            // check for the asset
            if (dataFetch.includes && dataFetch.includes.Asset) {
              dataFetch.includes.Asset.map((a, k) => {
                if (
                  p.fields.featureImage.sys &&
                  a.sys.id === p.fields.featureImage.sys.id
                ) {
                  newProjects[parseInt(p.fields.id)].featureImage =
                    "https:" + a.fields.file.url;
                }
              });
            }
          }

          // process the content
          let h = "";
          if (p.fields.content && p.fields.content.content) {
            p.fields.content.content.map((c, l) => {
              // process each content type
              // build html

              if (c.nodeType === "paragraph") {
                h += "<p>";
                c.content.map((t, l) => {
                  if (t.nodeType === "text") {
                    t.marks.map((tm, m) => {
                      if (tm.type === "bold") {
                        h += "<b>";
                      }
                      if (tm.type === "italic") {
                        h += "<i>";
                      }
                    });

                    h += t.value;
                    t.marks.map((tm, m) => {
                      if (tm.type === "bold") {
                        h += "</b>";
                      }
                      if (tm.type === "italic") {
                        h += "</i>";
                      }
                    });
                  }
                  if (t.nodeType === "hyperlink") {
                    h +=
                      "<a href='" +
                      t.data.uri +
                      "' target='_blank'>" +
                      t.content[0].value +
                      "</a>";
                  }
                });
                h += "</p>";
              }

              if (c.nodeType === "embedded-asset-block") {
                //get asset
                const assetID = c.data.target.sys.id;
                // iterate the assets and find the match
                dataFetch.includes.Asset.map((a, l) => {
                  if (c.data.target.sys && a.sys.id === c.data.target.sys.id) {
                    //TODO: If needed handle other media asset types here
                    // for now only images are used
                    if (a.fields.file.contentType.includes("image")) {
                      h +=
                        "<img class='image' src='" + a.fields.file.url + "' />";
                    }
                  }
                });
              }
            });
          }

          //console.log(h);
          if (h) {
            newProjects[parseInt(p.fields.id)].content = h;
          }
        }
      });
    }

    if (newProjects.length) {
      console.log(newProjects);
      const __np = [];
      let ind = 0;
      newProjects.map((np, ii) => {
        __np[ind] = np;
        ind++;
      });
      newData.projects = __np;
    }

    console.log(newData);
    set({ data: newData });
  },
}));

export { useData, data };
