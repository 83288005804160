import "./About.scss";
import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import useStore from "../../store";
import { useData } from "../../data/data";

function About(props) {
  const tl = useRef();
  const ref = useRef();
  const setShowAbout = useStore((state) => state.setShowAbout);
  const showAbout = useStore((state) => state.showAbout);
  const data = useData((state) => state.data);

  useEffect(() => {
    tl.current = gsap.timeline({});
    tl.current.to(ref.current, {
      autoAlpha: showAbout ? 1 : 0,
      zIndex: showAbout ? 1000 : 0,
      duration: 0.5,
    });
  }, [showAbout]);

  const close = () => {
    setShowAbout(false);
  };

  const emailClicked = () => {
    window.open("mailto:" + data.about.email, "_self");
  };

  return (
    <div ref={ref} className={`about`}>
      <div className="about-box">
        <button onClick={close} className="close-button">
          X
        </button>
        <div className="col-about-detail">{data.about.text}</div>
        <div onClick={emailClicked} className="col-about-detail-email">
          {data.about.emailText}
        </div>
      </div>
    </div>
  );
}

export default About;
