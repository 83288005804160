import "./EmailButton.scss";
import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import useStore from "../../store";
import { useData } from "../../data/data";

function EmailButton(props) {
  const tl = useRef();
  const ref = useRef();
  const AIState = useStore((state) => state.AIState);
  const setShowAbout = useStore((state) => state.setShowAbout);
  const showAbout = useStore((state) => state.showAbout);
  const data = useData((state) => state.data);

  const emailClicked = () => {
    window.open("mailto:" + data.about.email, "_self");
  };

  return <div onClick={emailClicked} className="email-button"></div>;
}

export default EmailButton;
