import { Canvas, useFrame, useThree } from "@react-three/fiber";
import "./MobileProjectWindows.scss";
import gsap from "gsap";
import { useEffect, useRef } from "react";
import useStore from "../../store";

import { useData } from "../../data/data";

const ProjectWindow = ({ project, index }) => {
  const setShowAbout = useStore((state) => state.setShowAbout);
  const setActiveMobileProject = useStore(
    (state) => state.setActiveMobileProject
  );
  const setPlaySound = useStore((state) => state.setPlaySound);
  const data = useData((state) => state.data);

  //const boxTex = useTexture("../assets/images/box-1.png");

  useEffect(() => {
    //console.log(index);
  }, []);

  const projectWindowClicked = (e) => {
    console.log("project clicked", index);
    setPlaySound("click");
    setActiveMobileProject(index);
  };

  return (
    <div
      className={`mobile-project-window ${data.projects[index].class || ""} ${
        index === data.projects.length - 1 ? "last" : ""
      }`}
    >
      <div className={`project-window-inner `}>
        <img
          className="box"
          src="./assets/images/box-1.png"
          onClick={projectWindowClicked}
        />
        <img className="image" src={data.projects[index].thumb} />

        <div className="titles-wrapper">
          <h2 className="sub-title">{data.projects[index].subTitle}</h2>
          <h1 className="title">{data.projects[index].title}</h1>
          <h3 className="tags">{data.projects[index].tags}</h3>
        </div>
      </div>
    </div>
  );
};

const MobileProjectWindows = () => {
  const AIState = useStore((state) => state.AIState);

  const ref = useRef();
  const tl = useRef();

  const data = useData((state) => state.data);

  useEffect(() => {
    // fade in or scale up html
    console.log(AIState);
    if (AIState === "ready") {
      //console.log("here");
      if (tl.current) tl.current.pause();
      tl.current = gsap.timeline({ delay: 3 });
      tl.current.to(".mobile-project-windows", {
        top: 0,
        autoAlpha: 1,
        duration: 0.5,
      });
      tl.current.fromTo(
        ".mobile-project-window",
        { y: 400 },
        {
          stagger: 0.05,
          top: 0,
          y: 0,
          duration: 0.2,
          ease: "back.out",
        },
        0
      );

      if (window.innerWidth < 1024) {
        tl.current.to(
          ".terminal",
          { y: -1000, duration: 0.5, ease: "circ.in" },
          0
        );
      }
    }
  }, [AIState]);
  return (
    <div ref={ref} className="mobile-project-windows">
      {data.projects.map((p, i) => {
        return <ProjectWindow key={"p_" + i} project={p} index={i} />;
      })}
    </div>
  );
};

export default MobileProjectWindows;
