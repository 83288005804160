import "./Login.scss";
import gsap from "gsap";
import { ScrambleTextPlugin } from "gsap/ScrambleTextPlugin";
import { TextPlugin } from "gsap/TextPlugin";
import { useEffect, useRef, useState } from "react";
import useStore from "../../store";

gsap.registerPlugin(ScrambleTextPlugin);
gsap.registerPlugin(TextPlugin);

function Login() {
  const appState = useStore((state) => state.appState);
  const setAppState = useStore((state) => state.setAppState);

  const setPlaySound = useStore((state) => state.setPlaySound);

  const ready = useRef(false);
  const button = useRef();

  const tl = useRef();

  const timeOfDay = new Date().getHours();
  let greeting;
  if (timeOfDay < 12) {
    greeting = "Good Morning";
  } else if (timeOfDay < 18) {
    greeting = "Good Afternoon";
  } else if (timeOfDay < 22) {
    greeting = "Good Evening";
  } else {
    greeting = "It's late";
  }

  const hideLogin = () => {
    if (tl.current) tl.current.pause();
    tl.current = gsap.timeline({ delay: 0.0 });
    // tl.current.to(".prompt-text", {
    //   text:
    //     "<br /><br /><br /><br />" +
    //     greeting +
    //     ". Would you like to play a game? Y",
    //   duration: 0.0,
    // });
    // tl.current.to([".prompt-cursor", ".prompt-text", ".prompt-title"], {
    //   autoAlpha: 0,
    //   duration: 0.5,
    //   ease: "power2.in",
    //   y: 10,
    //   stagger: 0.1,
    // });
    tl.current.call(
      () => {
        console.log("setting dtart");
        setAppState("start");
      },
      null,
      0
    );
  };

  useEffect(() => {
    // const handleKeys = (e) => {
    //   //console.log(ready.current);
    //   if (!ready.current) return;
    //   console.log(e.key + " : " + e.keyCode);
    //   if (
    //     e.key === "y" ||
    //     e.key === "Y" ||
    //     e.key === "Enter" ||
    //     e.key === "enter"
    //   ) {
    //     // anaimte out the text and set appstate to starting
    //     hideLogin();
    //   }
    // };
    // document.addEventListener("keydown", handleKeys);
    // return () => {
    //   document.removeEventListener("keydown", handleKeys);
    // };
  }, []);

  useEffect(() => {
    console.log(appState);
    if (appState === "login") {
      if (tl.current) tl.current.pause();
      tl.current = gsap.timeline({ delay: 0.0 });
      tl.current.set(".login", { zIndex: 1000 });
      tl.current.fromTo(
        ".title",
        { y: -10, rotationX: 90, opacity: 0.9 },
        { y: 0, rotationX: 0, opacity: 1, duration: 0.3, ease: "bounce.out" }
      );
      tl.current.to(
        ".inner-title",
        {
          top: 0,
          duration: 1000,
          scrambleText: {
            text: "namethemachine",
            tweenLength: true,
            revealDelay: 2,
            chars: "lowerCase",
          },
        },
        0
      );

      // tl.current.to(".prompt-cursor", {
      //   repeat: -1,
      //   yoyo: true,
      //   opacity: 1,
      //   duration: 0.3,
      //   repeatDelay: 0.2,
      //   ease: "power4.inOut",
      // });

      // tl.current.to(".prompt-text", {
      //   text:
      //     "<br /><br /><br /><br />" +
      //     greeting +
      //     ". Would you like to play a game?",
      //   duration: 2,
      // });
      // tl.current.call(() => {
      //   ready.current = true;
      // });
    } else {
      if (tl.current) tl.current.pause();
      tl.current = gsap.timeline({ delay: 0.0 });
      tl.current.to(".login", {
        autoAlpha: 0,
        scaleY: 0,
        scaleX: 2,
        duration: 0.2,
        ease: "bounce.inOut",
      });
      tl.current.set(".login", { zIndex: -1 });
      // tl.current.killTweensOf(".prompt-cursor");
    }
  }, [appState]);

  const start = () => {
    if (tl.current) tl.current.pause();
    tl.current = gsap.timeline({ delay: 0.0 });
    tl.current.call(() => {
      setPlaySound("click");
    });
    tl.current.to(
      ".inner-title",
      {
        top: 0,
        duration: 4.3,
        scrambleText: {
          text: "namethemachine",
          tweenLength: true,
          revealDelay: 0.1,
          chars: "lowerCase",
        },
      },
      0
    );
    tl.current.call(
      () => {
        setPlaySound("start");
      },
      null,
      0.05
    );
    tl.current.call(
      () => {
        setPlaySound("main loop");
      },
      null,
      0.1
    );
    tl.current.call(
      () => {
        hideLogin();
      },
      null,
      4.3
    );
  };

  return (
    <div className="login">
      <h1 className="prompt-title">
        [<span className="inner-title">01011101011010</span>]
      </h1>
      <button ref={button} onClick={start} className="start-button">
        initialize
      </button>
      {/* <h2 className="prompt" onClick={hideLogin}>
        <span className="prompt-text"></span>
        <span className="prompt-cursor"></span>
      </h2> */}
    </div>
  );
}

export default Login;
