import "./Globe.scss";
import gsap from "gsap";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import {
  Box,
  Cone,
  Environment,
  MeshTransmissionMaterial,
  OrbitControls,
  Plane,
  Sphere,
  Wireframe,
  useGLTF,
  useTexture,
} from "@react-three/drei";
import { Suspense, useEffect, useRef } from "react";
import {
  AdditiveBlending,
  AmbientLight,
  BackSide,
  Color,
  DoubleSide,
  MultiplyBlending,
  Vector3,
} from "three";

import {
  EffectComposer,
  Bloom,
  DepthOfField,
  ToneMapping,
  Noise,
  Pixelation,
  Glitch,
} from "@react-three/postprocessing";
import useStore from "../../store";

const latLngToVector3 = (lat, lon, radius) => {
  const phi = (lat * Math.PI) / 180;
  const theta = ((lon - 180) * Math.PI) / 180;
  const x = -radius * Math.cos(phi) * Math.cos(theta);
  const y = radius * Math.sin(phi);
  const z = radius * Math.cos(phi) * Math.sin(theta);
  return new Vector3(x, y, z);
};

function Globe() {
  const height = 0.01;
  const radius = 1.8;
  const globeState = useStore((state) => state.globeState);

  const tl = useRef();
  const canvasRef = useRef();

  useEffect(() => {
    console.log("globe stae", globeState);
    if (globeState === "show") {
      //show globe
      if (tl.current) tl.current.pause();
      tl.current = gsap.timeline({ delay: 0 });
      tl.current.fromTo(
        ".globe",
        { autoAlpha: 0, scaleY: -1, scaleX: 10 },
        {
          autoAlpha: 1,
          scaleY: 1,
          scaleX: 1,
          duration: 0.3,
          ease: "bounce.inOut",
        }
      );
    }
  }, [globeState]);

  const Marker = (props) => {
    const ref = useRef();
    const tl = useRef();
    const light = useRef();
    const plane1 = useRef();

    // plot all teh points on the globe
    // const regionIndex = useStore((state) => state.regionIndex);
    // const appState = useStore((state) => state.appState);

    useEffect(() => {
      const v = new Vector3();

      v.subVectors(props.position, new Vector3(0, 0, 0)).add(
        ref.current.position
      );
      ref.current.lookAt(v);
    }, []);

    useFrame(({ gl, scene, camera }) => {
      plane1.current.lookAt(camera.position);
    });

    return (
      <mesh ref={ref} {...props}>
        {/* <ringBufferGeometry args={[0.0449, 0.045, 32]} /> */}
        {/* <meshStandardMaterial
          color="#ba0309"
          side={THREE.DoubleSide}
          opacity={props.index === regionIndex ? 0.9 : 0.4}
          transparent={true}
        /> */}
        <mesh>
          <circleGeometry args={[0.03, 32]} />
          <meshStandardMaterial
            color="#D67128"
            side={DoubleSide}
            opacity={0.8}
            transparent={true}
          />
        </mesh>
        {/* <SpotLight
          ref={light}
          distance={20}
          intensity={1}
          angle={(Math.PI / 180) * 15}
          color={"#d9d7ff"}
          position={[0, 0, 0.5]}
          volumetric={true}
          // debug={true}
        /> */}
        <Cone
          args={[0.2, 0.8, 12, 1, true]}
          position={[0, 0, 0.25]}
          rotation={[(Math.PI / 180) * -90, 0, 0]}
        >
          <meshBasicMaterial
            map={useTexture("./assets/models/glow-spotlight.jpg")}
            blending={AdditiveBlending}
            opacity={0.3}
            transparent={true}
            color={"#D67128"}
            depthWrite={false}
            depthTest={true}
            side={BackSide}
          />
        </Cone>
        <group ref={plane1}>
          {/* <Plane
            args={[2.5, 1]}
            position={[0, 0, 1]}
            rotation={[(Math.PI / 180) * -10, 0, 0]}
          >
            <meshBasicMaterial
              map={useTexture("./assets/models/glow.png")}
              blending={AdditiveBlending}
              opacity={0.3}
              transparent={true}
              color={"#D67128"}
              depthWrite={false}
              depthTest={true}
              side={DoubleSide}
            />
          </Plane> */}
          <Plane
            ref={plane1}
            args={[1.5, 0.2]}
            position={[0, 0, 1]}
            rotation={[(Math.PI / 180) * 10, 0, 0]}
          >
            <meshBasicMaterial
              map={useTexture("./assets/models/glow.png")}
              blending={AdditiveBlending}
              opacity={0.7}
              transparent={true}
              color={"#ff8e24"}
              depthWrite={false}
              depthTest={true}
              side={DoubleSide}
            />
          </Plane>

          <Plane
            ref={plane1}
            args={[2.5, 0.4]}
            position={[0, 0, 1]}
            rotation={[(Math.PI / 180) * -30, 0, 0]}
          >
            <meshBasicMaterial
              map={useTexture("./assets/models/glow.png")}
              blending={AdditiveBlending}
              opacity={0.3}
              transparent={true}
              color={"#D67128"}
              depthWrite={false}
              depthTest={true}
              side={DoubleSide}
            />
          </Plane>
          <Plane
            ref={plane1}
            args={[4.5, 0.03]}
            position={[0, 0, 1]}
            rotation={[(Math.PI / 180) * -60, 0, 0]}
          >
            <meshBasicMaterial
              map={useTexture("./assets/models/glow.png")}
              blending={AdditiveBlending}
              opacity={0.2}
              transparent={true}
              color={"#46bbcc"}
              depthWrite={false}
              depthTest={true}
              side={DoubleSide}
            />
          </Plane>
        </group>
        {/* <Text
          visible={props.index === regionIndex && appState === "exploreRegions"}
          position={[0, 0.15, 0.1]}
          depthOffset={-0.1}
          fontSize={0.16}
          font={"/fonts/Netflix-Sans-Bold.woff"}
        >
          {props.title}
        </Text> */}
      </mesh>
    );
  };

  const Model = ({ ...props }) => {
    const ref = useRef();
    const { invalidate, camera, gl } = useThree();

    const targetRotationY = useRef(0);
    const targetRotationX = useRef(0);
    const initialRotationY = useRef(0);
    const initialRotationX = useRef(0);

    //const { scene, animations } = useGLTF("./assets/models/globe.glb");
    // const { actions, mixer } = useAnimations(animations, scene);

    const rotateGlobe = (lat, lng, offsetY = 0) => {
      const c = ref.current.rotation.y;
      const d = (-lng * (Math.PI / 180)) % (2 * Math.PI);
      const f = (Math.PI / (2 + offsetY)) * -1;

      // clean up and over rotations first
      ref.current.rotation.y = ref.current.rotation.y % (Math.PI * 2);
      ref.current.rotation.x = ref.current.rotation.x % (Math.PI * 2);

      targetRotationY.current = d + f + (Math.PI / 180) * 5;
      targetRotationX.current =
        ((lat * (Math.PI / 180)) % Math.PI) + (Math.PI / 180) * -15;
    };

    useEffect(() => {
      // scene.traverse((mesh, i) => {
      //   if (mesh.material) {
      //     mesh.material.envMapIntensity = 2.0;
      //     mesh.material.roughness = 0.0;
      //     mesh.material.metalness = 1.0;
      //     mesh.material.wireframe = true;
      //     mesh.material.color = new Color(0x5de7ff);
      //     mesh.material.opacity = 0.33;
      //     mesh.material.transparent = true;
      //     mesh.material.map = null;
      //     // mesh.material = new MeshPhongMaterial({
      //     //   color: new Color(props.color),
      //     // });
      //   }
      //   // mesh.frustumCulled = false;
      // });
      // actions["animation_0"].timeScale = 0.5;
      // actions["animation_0"].play();
      // 34.0549° N, 118.2426° W
      rotateGlobe(
        props.location ? props.location.loc.split(",")[0] : 34,
        props.location ? props.location.loc.split(",")[1] : -118
      );
      console.log("render globe again");
    }, []);

    useFrame((state, delta) => {
      // console.log("render");
      //ref.current.rotation.y += 0.001;
      //if (!props.isPaused) invalidate();
      ref.current.rotation.y +=
        (targetRotationY.current - ref.current.rotation.y) * 0.025;
      ref.current.rotation.x +=
        (targetRotationX.current - ref.current.rotation.x) * 0.02;
      targetRotationY.current -= 0.0002;
    });

    //useEffect(() => {
    //if (!props.isPaused) invalidate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    //}, [props.isPaused]);
    // useCursor(hovered);

    return (
      // <mesh {...props} ref={ref}>
      //   <primitive object={scene} scale={[30.0, 30.0, 30.0]} />
      // </mesh>
      // <group rotation={[0.8, 0, 0]}>
      <group ref={ref} scale={[1.3, 1.3, 1.3]}>
        <Sphere args={[2, 64, 64]} position={[0, 0, 0]}>
          <MeshTransmissionMaterial
            roughness={0.4}
            transmissionSampler
            // metalness={0.9}

            // clearcoat={0.05}
            // clearcoatRoughness={0.35}
            // displacementMap={useTexture("./images/explore-program/earth-1.jpg")}
            // displacementScale={0.01}

            transmission={1.0}
            transparent={true}
            opacity={0.6}
            color={"#5DE7FF"}
            map={useTexture("./assets/models/earth-1-2.png")}
            blending={AdditiveBlending}
            side={DoubleSide}
          />
        </Sphere>
        <Sphere args={[2.01, 64, 64]} position={[0, 0, 0]}>
          <meshPhysicalMaterial
            metalness={0.1}
            // roughness={0.}
            // clearcoat={1.0}
            color={"#5DE7FF"}
            opacity={0.9}
            transparent={true}
            blending={AdditiveBlending}
            map={useTexture("./assets/models/earth_political_alpha.png")}
          />
        </Sphere>

        <Marker
          key={"marker"}
          position={latLngToVector3(
            props.location ? props.location.loc.split(",")[0] : 34,
            props.location ? props.location.loc.split(",")[1] : -118,
            radius + height
          )}
          title={"loction"}
        />
      </group>
      // </group>
    );
  };

  return (
    // <Suspense fallback={null}>
    <Canvas
      // ref={canvasRef}
      className="globe"
      style={{ width: "32vw", height: "32vw", position: "relative" }}
      // camera={{ position: [0, 0, 0.0] }}
    >
      {/* <Model
          //isPaused={isPaused}
          position={[0, 0, 0]}
          scale={[0.1, 0.1, 0.1]}
        /> */}
      <ambientLight intensity={Math.PI / 2} />
      {/* <directionalLight position={[1, 3, 1]} /> */}

      {/* <Environment preset="night" /> */}
      <Model />
      <EffectComposer disableNormalPass>
        <Bloom
          luminanceThreshold={0}
          luminanceSmoothing={0.7}
          height={300}
          intensity={100}
        />
        <Noise opacity={0.9} />
        {/* <Glitch
          delay={[0.5, 1.5]} // min and max glitch delay
          duration={[0.01, 0.05]} // min and max glitch duration
          strength={[0.1, 0.2]} // min and max glitch strength
          // mode={GlitchMode.SPORADIC} // glitch mode
          //active // turn on/off the effect (switches between "mode" prop and GlitchMode.DISABLED)
          //ratio={0.85} // Threshold for strong glitches, 0 - no weak glitches, 1 - no strong glitches.
        /> */}
        <Pixelation
          granularity={2} // pixel granularity
        />
      </EffectComposer>

      {/* <OrbitControls /> */}
    </Canvas>
    // </Suspense>
  );
}

export default Globe;
