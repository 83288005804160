import React, { Suspense, useEffect, useRef } from "react";
import useStore from "../../store";
import useSound from "use-sound";

//import mainLoopFile from "./assets/sounds/ambient.mp3";

const Audio = () => {
  const soundOn = useStore((state) => state.soundOn);
  const setSoundOn = useStore((state) => state.setSoundOn);
  const playSound = useStore((state) => state.playSound);
  const setPlaySound = useStore((state) => state.setPlaySound);
  const stopSound = useStore((state) => state.stopSound);
  const setStopSound = useStore((state) => state.setStopSound);
  // const isARActive = useStore((state) => state.isARActive);

  const sounds = useRef([]);

  sounds.current["main loop"] = useSound(
    "./assets/sounds/WebsiteDrone_MDv2.mp3",
    {
      volume: soundOn ? 0.8 : 0,
      loop: true,
    }
  );

  sounds.current["click"] = useSound("./assets/sounds/UItick_MDv2.mp3", {
    volume: soundOn ? 0.3 : 0,
  });

  sounds.current["start"] = useSound("./assets/sounds/WebsiteINIT_v2.mp3", {
    volume: soundOn ? 0.7 : 0,
  });

  // sounds.current["crane"] = useSound("./assets/sounds/crane.mp3", {
  //   volume: soundOn ? 1 : 0,
  // });

  // sounds.current["spinner"] = useSound("./assets/sounds/spinner.mp3", {
  //   volume: soundOn ? 1 : 0,
  // });

  // sounds.current["tiles"] = useSound("./assets/sounds/tiles.mp3", {
  //   volume: soundOn ? 1 : 0,
  // });

  // sounds.current["sorry"] = useSound("./assets/sounds/sorry.mp3", {
  //   volume: soundOn ? 0.6 : 0,
  // });

  // sounds.current["dice"] = useSound("./assets/sounds/dice.mp3", {
  //   volume: soundOn ? 1 : 0,
  // });

  // sounds.current["snack"] = useSound("./assets/sounds/snack-time.mp3", {
  //   volume: soundOn ? 1 : 0,
  // });

  // sounds.current["train"] = useSound("./assets/sounds/train.mp3", {
  //   volume: soundOn ? 0.7 : 0,
  // });

  // sounds.current["zone 1"] = useSound("./assets/sounds/zone-1.mp3", {
  //   volume: soundOn ? 1 : 0,
  //   loop: true,
  // });

  // sounds.current["zone 2"] = useSound("./assets/sounds/zone-2.mp3", {
  //   volume: soundOn ? 1 : 0,
  //   loop: true,
  // });

  // sounds.current["zone 3"] = useSound("./assets/sounds/zone-3.mp3", {
  //   volume: soundOn ? 1 : 0,
  //   loop: true,
  // });

  // sounds.current["zone 4"] = useSound("./assets/sounds/zone-4.mp3", {
  //   volume: soundOn ? 1 : 0,
  //   loop: true,
  // });

  // sounds.current["zone 5"] = useSound("./assets/sounds/zone-5.mp3", {
  //   volume: soundOn ? 1 : 0,
  //   loop: true,
  // });

  // sounds.current["end"] = useSound("./assets/sounds/end.mp3", {
  //   volume: soundOn ? 1 : 0,
  // });

  // sounds.current["gold tip ooze"] = useSound(
  //   "./assets/sounds/GoldTip_Ooze.mp3",
  //   {
  //     volume: soundOn ? 1 : 0,
  //     loop: true,
  //   }
  // );

  useEffect(() => {
    // handles rememebre user mute prefrenes
    //console.log(soundOn, localStorage.getItem("soundOn"));
    const _soundOn = localStorage.getItem("soundOn");
    if (_soundOn !== null && _soundOn !== undefined) {
      setSoundOn(_soundOn === "true");
    }

    document.onvisibilitychange = function () {
      //console.log(document.visibilityState);
      if (document.visibilityState === "hidden") {
        if (window.Howler) {
          window.Howler.mute(true);
        }
      }
      if (document.visibilityState === "visible") {
        if (window.Howler) {
          window.Howler.mute(false);
        }
      }
    };
  }, []);

  useEffect(() => {
    // sets user mute pref
    //console.log("sound on changed", soundOn);
    localStorage.setItem("soundOn", soundOn);
  }, [soundOn]);

  useEffect(() => {
    if (!playSound || !sounds.current[playSound]) return;
    //console.log(sounds.current[playSound]);
    sounds.current[playSound][1].stop();
    sounds.current[playSound][0]();
    setPlaySound(null);
  }, [playSound]);

  useEffect(() => {
    if (!stopSound || !sounds.current[stopSound]) return;
    // sounds.current[stopSound].volume = 0.3;
    sounds.current[stopSound][1].stop();
    setStopSound(null);
  }, [stopSound]);

  return null;
};

export default Audio;
