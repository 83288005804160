import "./Network.scss";
import gsap from "gsap";
import { Player } from "@lottiefiles/react-lottie-player";
import networkLottie from "../../assets/animations/network.json";
import useStore from "../../store";
import { useEffect, useRef } from "react";

const Network = () => {
  const ref = useRef();
  const tl = useRef();
  const networkState = useStore((state) => state.networkState);

  const lottiePlayer = useRef();

  useEffect(() => {
    if (networkState === "show") {
      //show network
      if (tl.current) tl.current.pause();
      tl.current = gsap.timeline({ delay: 0 });
      tl.current.fromTo(
        ".network",
        {
          autoAlpha: 0,
          scaleY: -1,
          scaleX: 20,
          transformOrigin: "100% 20%",
        },
        {
          autoAlpha: 0.7,
          scaleY: 1,
          scaleX: 1,
          duration: 0.5,
          ease: "bounce.inOut",
        }
      );
    }
  }, [networkState]);

  useEffect(() => {}, []);

  return (
    <div className="network">
      <Player
        // animationData={animationData}
        src={networkLottie}
        loop
        autoplay
        //ref={lottiePlayer}
        className="player"
        // height={400}
        // width={400}
      />
    </div>
  );
};

export default Network;
